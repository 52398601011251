import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';

import { createTerminal, getTerminals } from "store/actions/dashboard/retail/terminals/terminals.action";

import useAutosuggestion from 'hooks/useAutosuggestion';

import Paths from 'constants/path.constants';

import { NAME_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { hasOneOfPermissions } from 'utils/permissions';

import userInfoType from 'types/profile/userInfo.type';

/** Terminal Creating Popup Component */
const TerminalCreateComponent = ({
    isSaving,
    createTerminal,
    getTerminals,
    globalCompanyId,
    userInfo,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const navigate = useNavigate();

    const [ betshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP, 
        autoGet: true
    });

    const allCompanies = userInfo?.companies ?? [];

    const isCurrentCompanyTest = allCompanies.find(p => p.id === globalCompanyId)?.isTesting ?? false;

    const handleForm = () => {
        validateFields()
            .then((data) => {
                createTerminal(
                    data,
                    record => {
                        if (
                            hasOneOfPermissions(
                                [
                                    { resource: PERMISSION_RESOURCE.TERMINAL_GENERALINFO, action: PERMISSION_ACTION.VIEW },
                                ]
                            )
                        ) {
                            navigate(`${Paths.TERMINALS_EDIT}/${record.id}?name=${record.name}`);
                        } else {
                            getTerminals();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.terminals.createTerminal')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    name: "",
                    isTesting: isCurrentCompanyTest
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                { min: 3, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.name')}`}
                                maxLength={50}
                                autoFocus={true}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.betshop')} *`}
                            name="betShopId"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={
                                    [
                                        ...betshopNames.map(item => (
                                            { value: item.id, text: item.name }
                                        ))
                                    ]
                                }
                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.terminals.betshop")}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.terminals.isTestTerminal')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** TerminalCreateComponent propTypes
    * PropTypes
*/
TerminalCreateComponent.propTypes = {
    /** Redux state property, is true when creating terminal request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create terminal */
    createTerminal: PropTypes.func,
    /** Redux action to get terminals */
    getTerminals: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createTerminal: (terminal, onSuccess) => {
            dispatch(createTerminal(terminal, onSuccess));
        },

        getTerminals: () => {
            dispatch(getTerminals());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.terminals.isSaving,
        globalCompanyId: state.common.globalCompanyId,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TerminalCreateComponent)
