import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setBetHistoryActionBefore, setBetHistoryActionFinished } from "./betHistory.action";

import {
    SET_TERMINAL_SETTLED_BET_HISTORY,
    SET_TERMINAL_SETTLED_BET_HISTORY_SORTING,
    SET_TERMINAL_SETTLED_BET_HISTORY_FILTERS,
    SET_TERMINAL_SETTLED_BET_TOTALS,
} from "../../../actionTypes";
import { BET_STATE } from "constants/bet.constants";

const setTerminalSettledBetHistory = (bets, add) => ({
    type: SET_TERMINAL_SETTLED_BET_HISTORY,
    payload: { bets, add },
});

export const setTerminalSettledBetHistorySorting = sorting => ({
    type: SET_TERMINAL_SETTLED_BET_HISTORY_SORTING,
    payload: { sorting },
});

export const setTerminalSettledBetHistoryFilters = (filters, keepPage) => ({
    type: SET_TERMINAL_SETTLED_BET_HISTORY_FILTERS,
    payload: { filters, keepPage },
});

const setTerminalSettledBetTotals = totals => ({
    type: SET_TERMINAL_SETTLED_BET_TOTALS,
    payload: { totals },
});

export const getTerminalSettledBetHistory = nextPage => {
    return (dispatch, getState) => {
        const productT = "terminal"

        const betHistory = getState().betHistory[productT].settled;

        const filters = { ... betHistory.filters };
        const sorting = { ... betHistory.sorting };
        const total = betHistory.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;

        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if (betHistory.filters.status === BET_STATE.PAID_OUT) {
            params.IsPaidout = true;
            params.status = "";
        }

        if (betHistory.filters.status === BET_STATE.CASHED_OUT) {
            params.IsCachedout = true;
            params.status = "";
        }

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }

        dispatch(setBetHistoryActionBefore());

        return axios({
            url: ApiUrls.GET_TERMINAL_SETTLED_BET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setTerminalSettledBetHistory(bets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setTerminalSettledBetHistorySorting({ ...sorting, page: page }));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}

export const getTerminalSettledBetTotals = () => {
    return (dispatch, getState) => {
        const productT = "terminal"

        const betHistory = getState().betHistory[productT].settled;

        const filters = { ... betHistory.filters };

        let params = {
            ...filters
        };

        return axios({
            url: ApiUrls.GET_TERMINAL_BETS_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setTerminalSettledBetTotals(totals));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {

            });
    }
}
