import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";

import { addProjectCurrency, saveProjectCurrency } from "store/actions/dashboard/projects/currencies.action";
import { getSystemAvailableCurrencies } from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";

import useProjectType from 'hooks/useProjectType';

import { binaryToFlags } from "utils/common";
import { isFormChanged } from "utils/form";

import { POPUP_SIZE } from 'constants/common.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';

import currencyType from "types/currency/currency.type";


/** Currency Creating Popup Component */
const CurrencyAddEditComponent = ({
    isSaving,
    isAvailableLoading,
    systemAvailableCurrencies,
    currencies,
    addProjectCurrency,
    saveProjectCurrency,
    getSystemAvailableCurrencies,
    editingCurrency,
    globalProjectId,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(editingCurrency ? false : true);

    const [selectedCur, setSelectedCur] = useState(editingCurrency ? editingCurrency.toUpperCase() : "");
    const [systemValues, setSystemValues] = useState({})

    /** Load system currencies */
    useEffect(() => {
        getSystemAvailableCurrencies();
    }, [])


    /** Get initial values for form
       * @function
       * @returns {object} - initial values
       * @memberOf CurrencyAddEditComponent
   */
    const getDefaultValues = () => {

        if (editingCurrency) {
            const cur = currencies.find(c => c.code === editingCurrency);
            if (cur) {
                return {
                    code: cur.code,
                    decimalCount: cur.decimalCount,
                    //rate: cur.rateSourceType === RATE_SOURCE_TYPE.DEFAULT ? "" : cur.rate,
                }
            }
        }

        return {
            code: undefined,
            decimalCount: 0,
            //rate: "",
        };
    }

    /** Fires when form submitted
       * @function
       * @memberOf CurrencyAddEditComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    code: data.code.toUpperCase(),
                    decimalCount: data.decimalCount,
                    //rate: data.rate ? Number(data.rate) : null
                    rate: setSystemValues.rate ?? 1
                }

                if (editingCurrency) {
                    const cur = currencies.find(c => c.code === editingCurrency);
                    saveProjectCurrency({
                        ...d,
                        id: cur.id,
                        enabled: cur.enabled,
                        isDefault: cur.isDefault,
                    }, onClose);
                } else {
                    addProjectCurrency(d, globalProjectId, onClose);
                }

            }).catch(() => { })
    }


    /** Fill default value for rate */
    useEffect(() => {
        const selectedCurrency = systemAvailableCurrencies.find(c => c.code.toLowerCase() === selectedCur.toLowerCase())
        if (selectedCurrency && selectedCur && !editingCurrency) {
            const values = {
                decimalCount: selectedCurrency.decimalCount || 0,
                code: selectedCurrency.code
            }
            setFieldsValue(values);
            setSystemValues({
                ...values,
                rate: selectedCurrency.rate
            })
        } else if (systemAvailableCurrencies.length > 0 && editingCurrency) {
            const cur = currencies.find(c => c.code.toLowerCase() === editingCurrency.toLowerCase());
            const values = {
                decimalCount: cur.decimalCount,
                code: cur.code
            }
            setFieldsValue(values);
            setSystemValues({
                ...values,
                rate: selectedCurrency?.rate ?? ""
            })
        }
    }, [selectedCur, systemAvailableCurrencies])


    return (
        <Modal
            title={editingCurrency ? t('backoffice.currencies.editCurrency') : t('backoffice.currencies.addCurrency')}
            cancelText={t('backoffice.common.cancel')}
            okText={editingCurrency ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={getDefaultValues()}
                onValuesChange={(changed, formValues) => {
                    if (editingCurrency) {
                        setIsFormTouched(isFormChanged({ ...formValues }, { ...getDefaultValues() }))
                    }
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.currencies.selectCurrency')} *`}
                            name="code"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className={editingCurrency ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={
                                    systemAvailableCurrencies
                                        .filter(item => !currencies.some(c => c.code.toUpperCase() === item.code.toUpperCase()))
                                        .map(item => (
                                            { value: item.code.toUpperCase(), text: item.code.toUpperCase() + " - " + item.name }
                                        ))
                                }
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                loading={isAvailableLoading}
                                disabled={Boolean(editingCurrency)}
                                placeholder={t('backoffice.currencies.selectCurrencyPlaceholder')}
                                onChange={v => {
                                    setSelectedCur(v.toUpperCase());
                                    // if (v.toUpperCase() === "EUR") {
                                    //     setFieldsValue({ rate: "1" })
                                    // }
                                }}
                                search={true}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col { ...mainColsSizes }>
                        <Form.Item
                            label={t('backoffice.currencies.rate')}
                            name="rate"
                            rules={[
                                { type: "number", max: 10000, message: t('backoffice.validation.mustBeLess').replace("%X%", 10000), transform: numberTransform },
                                { type: "number", min: 0.000001, message: t('backoffice.validation.mustBeMore').replace("%X%", 0.000001), transform: numberTransform }
                            ]}
                            className={'rt--general-form-item rt--form-item-with-suffix' + (selectedCur === "EUR" ? ' rt--form-item-disabled' : "")}
                            data-placeholder={systemValues.rate ? `${t('backoffice.currencies.default')}: ${systemValues.rate}` : `${t('backoffice.common.enter')} ${t('backoffice.currencies.rate')}`}
                        >
                            <NumericInput
                                placeholder={systemValues.rate ? `${t('backoffice.currencies.default')}: ${systemValues.rate}` : `${t('backoffice.common.enter')} ${t('backoffice.currencies.rate')}`}
                                disabled={selectedCur === "EUR"}
                                suffix={getDefaultValues().rateSourceType !== RATE_SOURCE_TYPE.DEFAULT ? (
                                    <Tooltip
                                        title={t('backoffice.currencies.resetToDefault')}
                                        trigger={["hover"]}
                                        placement="bottom"
                                        enableMobile={true}
                                    >
                                        <i
                                            className="icon-reset rt--font-bigest"
                                            onClick={() => {
                                                setFieldsValue({ rate: "" });
                                                setIsFormTouched(true)
                                            }}
                                        />
                                    </Tooltip>
                                ) : undefined}
                            />

                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.currencies.decimalCount')}
                            name="decimalCount"
                            className={'rt--form-item-without-margin' + (editingCurrency ? " rt--form-item-disabled" : "")}
                        >
                            <Select
                                options={[
                                    { value: 0, text: "0" },
                                    { value: 1, text: "0,1" },
                                    { value: 2, text: "0,12" },
                                    { value: 3, text: "0,123" },
                                    { value: 4, text: "0,1234" },
                                    { value: 5, text: "0,12345" },
                                    { value: 6, text: "0,123456" },
                                    { value: 7, text: "0,1234567" },
                                    { value: 8, text: "0,12345678" },
                                ]}
                                disabled={Boolean(editingCurrency)}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.currencies.decimalCount')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** CurrencyAddEditComponent propTypes
    * PropTypes
*/
CurrencyAddEditComponent.propTypes = {
    /** Redux state property, is true when adding currency request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to add currency for project */
    addProjectCurrency: PropTypes.func,
    /** Redux action to edit currency for project */
    saveProjectCurrency: PropTypes.func,
    /** Redux action to get system available currencies */
    getSystemAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the array of currencies  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, represents the array of system available currencies  */
    systemAvailableCurrencies: PropTypes.arrayOf(currencyType),
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Current editing currecny */
    editingCurrency: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        addProjectCurrency: (currency, id, onSuccess) => {
            dispatch(addProjectCurrency(currency, id, onSuccess));
        },
        saveProjectCurrency: (currency, onSuccess) => {
            dispatch(saveProjectCurrency(currency, onSuccess));
        },
        getSystemAvailableCurrencies: () => {
            dispatch(getSystemAvailableCurrencies())
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
        currencies: state.projects.edit.currencies,
        systemAvailableCurrencies: state.systemCurrencies.systemAvailableCurrencies,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyAddEditComponent)
