const PATHS = {
	AUTHENTICATE: "/Account/Login",
	REFRESH_TOKEN: "/Account/RefreshToken",
	AUTHENTICATE_QR: "/Account/GoogleAuthenticatorQR",
	AUTHENTICATE_TOKEN: "/Account/GoogleAuthenticateToken",
	RESET_PASSWORD: "/Account/ResetPassword",
	SET_PASSWORD: "/Account/SetPassword",
	GET_ACCOUNT_PROJECT_ACCESS: "/Account/ProjectsAccess",
	ADD_ACCOUNT_PROJECT_ACCESS: "/Account/AddProjectAccess",
	DELETE_ACCOUNT_PROJECT_ACCESS: "/Account/DeleteProjectAccess",
	GET_ACCOUNT_PASSWORD_SETTINGS: "/Account/PasswordSettings",
	UPLOAD_AVATAR: "/Account/UploadAvatar",
	UPLOAD_PROFILE_AVATAR: "/Account/UploadProfileAvatar",
	DELETE_ACCOUNT_AVATAR: "/Account/DeleteAvatar",
	DELETE_ACCOUNT_PROFILE_AVATAR: "/Account/DeleteProfileAvatar",
	CHANGE_ACCOUNT_STATE: "/Account/ChangeState",
	FORCE_LOGOUT_ACCOUNT: "/Account/ForceLogout",
	GET_USER_INFO: "/Account/Info",
	GET_PROFILE: "/Account/Profile",
	SAVE_PROFILE: "/Account/SaveProfile",
	GET_FORMAT_SETTINGS: "/Account/GetFormattingSetting",
	SAVE_FORMAT_SETTINGS: "/Account/SaveFormattingSetting",
	CHANGE_PASSWORD: "/Account/ChangePassword",
	CHANGE_ACCOUNT_CURRENCY: "/Account/ChangeCurrency",
	CHANGE_ACCOUNT_PROJECT: "/Account/ChangeProject",
	CHANGE_ACCOUNT_PASSWORD: "/Account/ChangeUsersPassword",
	UNLOCK_ACCOUNT: "/Account/ResetFailedLoginAttemptsState",
	SWITCH_ACCOUNT: "/Account/Switch",
	SWITCH_BACK_ACCOUNT: "/Account/SwitchBack",
	GET_CURRENCIES: "/Account/Currencies",
	CHANGE_NOTIFICATION_SETTINGS: "/Account/ChangeNotificationSettings",

	GET_CHAT_ACCOUNT: "/Chat/Account",
	GET_CHAT_STATUS: "/Chat/Status",

	GET_DASHBOARD_OPERATIONS_PIE_CHART: "/Dashboard/PieChart",
	GET_DASHBOARD_OPERATIONS_LINE_CHART: "/Dashboard/OperationsLineChart",
	GET_DASHBOARD_OPERATIONS_TOP_AGENTS: "/Dashboard/OperationsTopAgents",

	GET_DASHBOARD_FINANCES_TOP_AGENTS: "/Dashboard/FinancesTopAgents",
	GET_DASHBOARD_FINANCES_TOP_PLAYERS: "/Dashboard/FinancesTopPlayers",
	GET_DASHBOARD_FINANCES_LINE_CHART: "/Dashboard/FinancesLineChart",
	GET_DASHBOARD_RETAIL_LINE_CHART: "/Dashboard/RetailLineChart",

	GET_DASHBOARD_TOP_CASHIERS: "/Dashboard/TopCashiers",
	GET_DASHBOARD_TOP_BETSHOPS: "/Dashboard/TopBetshops",
	GET_DASHBOARD_TOP_PLAYERS: "/Dashboard/TopPlayers",
	GET_DASHBOARD_TOP_BETSHOP_OWNERS: "/Dashboard/TopBetshopOwners",

	GET_DASHBOARD_RETAIL_CARDS: "/Dashboard/RetailCards",

	GET_COMPANIES: "/Company/All",
	GET_ALL_COMPANIES: "/Company/Available",
	CREATE_COMPANY: "/Company/Create",
	GET_COMPANY_GENERAL_INFO: "/Company/GeneralInfo",
	SAVE_COMPANY_GENERAL_INFO: "/Company/SaveGeneralInfo",

	GET_COMPANY_LANGUAGES: "/Company/LanguageSettings",
	GET_COMPANY_AVAILABLE_LANGUAGES: "/Company/AvailableLanguages",

	COMPANY_GET_PASSWORD_SETTINGS: "/Company/PasswordSettings",
	COMPANY_SAVE_PASSWORD_SETTINGS: "/Company/SavePasswordSettings",
	COMPANY_GET_TOKEN_SETTINGS: "/Company/TokenSettings",
	COMPANY_SAVE_TOKEN_SETTINGS: "/Company/SaveTokenSettings",
	COMPANY_GET_AUTHENTICATION_SETTINGS: "/Company/AuthenticationSettings",
	COMPANY_SAVE_AUTHENTICATION_SETTINGS: "/Company/SaveAuthenticationSettings",

	GET_NETWORK_SUB_NODES: "/Network/SubNodes",
	GET_NETWORK_LAYERS: "/Network/Layers",
	GET_NETWORK_ROOT_NODES: "/Network/RootNodes",

	GET_SYSTEM_CURRENCIES: "/SystemCurrency/All",
	EXPORT_SYSTEM_CURRENCIES: "/SystemCurrency/Export",
	GET_SYSTEM_AVAILABLE_CURRENCIES: "/SystemCurrency/SystemAvailable",
	CREATE_SYSTEM_CURRENCY: "/SystemCurrency/Create",
	SAVE_SYSTEM_CURRENCY: "/SystemCurrency/Save",
	GET_AVAILABLE_CURRENCIES: "/SystemCurrency/Available",

	GET_SYSTEM_LANGUAGES: "/SystemLanguage/All",
	EXPORT_SYSTEM_LANGUAGES: "/SystemLanguage/Export",
	GET_SYSTEM_AVAILABLE_LANGUAGES: "/SystemLanguage/SystemAvailable",
	GET_SYSTEM_AVAILABLE_PUBLISHED_LANGUAGES: "/SystemLanguage/SystemAvailablePublished",
	CREATE_SYSTEM_LANGUAGE: "/SystemLanguage/Create",
	GET_AVAILABLE_LANGUAGES: "/SystemLanguage/Available",

	GET_AGENTS: "/Agent/All",
	EXPORT_AGENTS: "/Agent/Export",
	CREATE_AGENT: "/Agent/Create",
	DELETE_AGENT: "/Agent/Delete",
	REPARENT_AGENT: "/Agent/ReparentAgent",
	SUSPEND_AGENT: "/Agent/Suspend",
	UNSUSPEND_AGENT: "/Agent/Unsuspend",
	GET_AVAILABLE_AGENTS_FOR_REPARENTING: "/Agent/GetAvailableAgentsForReparenting",
	GET_AVAILABLE_AGENTS_FOR_PLAYERS_REPARENTING: "/Agent/GetAvailableAgentsForPlayersReparenting",
	FORCE_LOGOUT_AGENT: "/Agent/ForceLogout",
	GET_AGENT_GENERAL_INFO: "/Agent/GeneralInfo",
	GET_AGENT_GENERAL_INFO_LAT_AM: "/Agent/GeneralInfoLatAm",
	SAVE_AGENT_GENERAL_INFO: "/Agent/SaveGeneralInfo",
	SAVE_AGENT_GENERAL_INFO_LAT_AM: "/Agent/SaveGeneralInfoLatAm",
	GET_AGENT_ADDITIONAL_ACCESSES: "/Agent/AdditionalAccesses",
	SAVE_AGENT_ADDITIONAL_ACCESSES: "/Agent/SaveAdditionalAccesses",
	GET_AGENT_CURRENCIES: "/Agent/Currencies",
	ADD_AGENT_CURRENCY: "/Agent/AddCurrency",
	ACTIVATE_AGENT_CURRENCY: "/Agent/ActivateCurrency",
	DEACTIVATE_AGENT_CURRENCY: "/Agent/DeactivateCurrency",
	GET_AGENT_AVAILABLE_CURRENCIES: "/Agent/AvailableCurrencies",
	GET_AGENT_LIMITS: "/Agent/Limits",
	SAVE_AGENT_LIMITS: "/Agent/SaveLimits",
	GET_AGENT_REGISTRATION_FORM: "/Agent/AgentRegistrationForm",
	GET_AGENTS_WALLET_TOTALS: "/Agent/AgentsWalletsTotals",
	GET_AGENT_COMMISSION_SETTINGS: "/Agent/CommissionSettings",
	SAVE_AGENT_COMMISSION_SETTINGS: "/Agent/SaveCommissionSettings",

	GET_COMMISSION_PLANS: "/CommissionPlan/All",
	CREATE_COMMISSION_PLAN: "/CommissionPlan/Create",
	DELETE_COMMISSION_PLAN: "/CommissionPlan/Delete",
	ADD_COMMISSION_PLAN: "/CommissionPlan/Add",
	REMOVE_COMMISSION_PLAN: "/CommissionPlan/Remove",
	GET_AVAILABLE_COMMISSION_PLANS: "/CommissionPlan/Available",
	ACTIVATE_COMMISSION_PLAN: "/CommissionPlan/Activate",
	DEACTIVATE_COMMISSION_PLAN: "/CommissionPlan/Deactivate",
	RECALCULATE_COMMISSION_PLAN: "/CommissionPlan/Recalculate",

	GET_COMMISSION_PLAN_SETTINGS: "/CommissionPlan/Settings",
	SAVE_COMMISSION_PLAN_SETTINGS: "/CommissionPlan/SaveSettings",
	GET_COMMISSION_PLAN_SPORT_SETTINGS: "/CommissionPlan/SportSettings",
	SAVE_COMMISSION_PLAN_SPORT_SETTINGS: "/CommissionPlan/SaveSportSettings",

	GET_COMMISSION_PLAN_AGENTS: "/CommissionPlan/AssignedAgents",
	EXPORT_COMMISSION_PLAN_AGENTS: "/CommissionPlan/ExportAssignedAgents",

	GET_COMMISSION_CALCULATIONS_REPORT: "/CommissionPlan/CalculationHistory",
	GET_COMMISSION_CALCULATIONS_REPORT_EXPORT: "/CommissionPlan/ExportCalculationHistory",
	GET_AGENT_CALCULATION_HISTORY: "/CommissionPlan/AgentCalculationHistory",
	EXPORT_AGENT_CALCULATION_HISTORY: "/CommissionPlan/ExportAgentCalculationHistory",
	GET_AGENT_PERFORMANCE_REPORT_TOTALS: "/Report/AgentPerformanceTotals",

	GET_AGENT_PERFORMANCE_REPORT: "/Report/AgentPerformance",
	EXPORT_AGENT_PERFORMANCE_REPORT: "/Report/ExportAgentPerformance",

	GET_PLAYER_PERFORMANCE_REPORT: "/Report/PlayerPerformance",
	EXPORT_PLAYER_PERFORMANCE_REPORT: "/Report/ExportPlayerPerformance",
	GET_PLAYER_PERFORMANCE_REPORT_TOTALS: "/Report/PlayerPerformanceTotals",

	GET_BETSHOP_PERFORMANCE_REPORT: "/Report/BetshopPerformance",
	EXPORT_BETSHOP_PERFORMANCE_REPORT: "/Report/ExportBetshopPerformance",
	GET_BETSHOP_PERFORMANCE_REPORT_TOTALS: "/Report/BetshopPerformanceTotals",

	GET_CASHIER_PERFORMANCE_REPORT: "/Report/CashierPerformance",
	EXPORT_CASHIER_PERFORMANCE_REPORT: "/Report/ExportCashierPerformance",

    GET_PRODUCT_REPORT: "/Report/ProductReport",

	GET_PLAYERS_PRODUCT_REPORT: "/Report/PlayersProductReport",
	GET_PLAYERS_PRODUCT_REPORT_TOTALS: "/Report/PlayersProductReportTotals",
	EXPORT_PLAYERS_PRODUCT_REPORT: "/Report/ExportPlayersProductReport",

	GET_AGENTS_PRODUCT_REPORT: "/Report/AgentsProductReport",
	GET_AGENTS_PRODUCT_REPORT_TOTALS: "/Report/AgentsProductReportTotals",
	EXPORT_AGENTS_PRODUCT_REPORT: "/Report/ExportAgentsProductReport",

	GET_RETAIL_PROVIDERS: "/CommissionPlan/RetailProviders",

	GET_AGENT_WALLETS: "/Wallet/AgentWallets",
	GET_WALLET_HISTORY: "/Wallet/History",
	EXPORT_WALLET_HISTORY: "/Wallet/Export",
	AGENT_DEPOSIT_FROM_BALANCE: "/Wallet/AgentDepositFromBalance",
	AGENT_DEPOSIT_TO_CREDIT_LINE: "/Wallet/AgentDepositToCreditLine",
	AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT: "/Wallet/AgentDepositFromBalancePayDebt",
	AGENT_DEPOSIT_FROM_CREDIT: "/Wallet/AgentDepositFromCredit",
	AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT: "/Wallet/AgentDepositFromCreditPayDebt",
	AGENT_WITHDRAWAL_FROM_BALANCE: "/Wallet/AgentWithdrawalFromBalance",
	AGENT_WITHDRAW_FROM_CREDIT_LINE: "/Wallet/AgentWithdrawFromCreditLine",
	AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT: "/Wallet/AgentWithdrawalFromBalancePayDebt",
	AGENT_WITHDRAWAL_FROM_CREDIT: "/Wallet/AgentWithdrawalFromCredit",
	AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT: "/Wallet/AgentWithdrawalFromCreditPayDebt",
	AGENT_GIVE_CREDIT: "/Wallet/AgentGiveCredit",
	AGENT_PAY_DEBT: "/Wallet/PayDebt",
	AGENT_PAY_DEBT_WITH_DEBT: "/Wallet/PayDebtWithDebt",
	PLAYER_DEPOSIIT: "/Wallet/PlayerDeposit",
	PLAYER_WITHDRAWAL: "/Wallet/PlayerWithdrawal",
	PLAYER_EPOS_DEPOSIT: "/Wallet/PlayerEPOSDeposit",
	PLAYER_EPOS_WITHDRAWAL: "/Wallet/PlayerEPOSWithdrawal",
	PLAYER_EPOS_WITHDRAWAL_FREEZE: "/Wallet/PlayerEPOSWithdrawalFreeze",
	BETSHOP_DEPOSIIT: "/Wallet/BetShopDeposit",
	BETSHOP_WITHDRAWAL: "/Wallet/BetShopWithdrawal",
	WALLET_ROLLBACK_TRANSACTION: "/Wallet/RollbackTransaction",
	CONFIRM_TRANSACTION_REQUEST: "/Transaction/ConfirmRequest",
	CONFIRM_TRANSACTION_REQUEST_FREEZE: "/Transaction/ConfirmRequestFreeze",
	REJECT_TRANSACTION_REQUEST: "/Transaction/RejectRequest",
	REJECT_TRANSACTION_REQUEST_FREEZE: "/Transaction/RejectRequestFreeze",
	ADJUST_TRANSACTION_REQUEST: "/Transaction/AdjustTransaction",
	PROJECT_BALANCE_DEPOSIT: "/Wallet/ProjectBalanceDeposit",
	PROJECT_BALANCE_WITHDRAW: "/Wallet/ProjectBalanceWithdraw",
	SUPER_AGENT_PAY_DEBT: "/Wallet/SuperAgentPayDebt",
	BETSHOP_LIMIT_ADJUSTMENT: "/Wallet/BetShopLimitAdjustment",
	RESET_BETSHOP_LIMIT: "/Wallet/ResetBetShopLimit",

	GET_AGENT_TRANSACTIONS_REPORT: "/Report/AgentTransactions",
	GET_BETSHOP_TRANSACTIONS_REPORT: "/Report/BetShopTransactions",
	EXPORT_AGENT_TRANSACTIONS_REPORT: "/Report/ExportAgentTransactions",
	EXPORT_BETSHOP_TRANSACTIONS_REPORT: "/Report/ExportBetShopTransactions",

	GET_PLAYERS: "/Player/All",
	EXPORT_PLAYERS: "/Player/Export",
	CREATE_PLAYER: "/Player/Create",
	SET_PLAYER_STATE: "/Player/SetState",
	GET_PLAYER_GENERAL_INFO: "/Player/GeneralInfo",
	SAVE_PLAYER_GENERAL_INFO: "/Player/SaveGeneralInfo",
	GET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID: "/Player/GeneralInfoByExternalId",
	REPARENT_PLAYER: "/Player/Reparent",
	BULK_REPARENT_PLAYER: "/Player/BulkReparent",
	SET_PLAYER_PASSWORD: "/Player/SetPassword",
	GET_PLAYER_REGISTRATION_FORM: "/Player/PlayerRegistrationForm",
	GET_PLAYER_BETS: "/Player/Bets",
	EXPORT_PLAYER_BETS: "/Player/ExportBets",
	PLAYER_TOTALS: "/Player/PlayersWalletsTotals",
	PLAYER_TRANSACTION_HISTORY: "/Player/TransactionHistory",
	EXPORT_PLAYER_TRANSACTION_HISTORY: "/Player/ExportTransactionHistory",
	RESEND_PLAYERS_AGENT: "/Player/ResendAgent",

	GET_EPOS_REQUEST: "/Transaction/EPOSRequest",

	GET_RETAIL_PENDING_BET_HISTORY: "/Bet/RetailPendingBets",
	GET_RETAIL_SETTLED_BET_HISTORY: "/Bet/RetailSettledBets",
	EXPORT_RETAIL_PENDING_BET_HISTORY: "/Bet/ExportRetailPendingBets",
	EXPORT_RETAIL_SETTLED_BET_HISTORY: "/Bet/ExportRetailSettledBets",
	GET_RETAIL_BETS_TOTALS: "/Bet/RetailBetsTotals",

    GET_TERMINAL_PENDING_BET_HISTORY: "/Bet/TerminalPendingBets",
	GET_TERMINAL_SETTLED_BET_HISTORY: "/Bet/TerminalSettledBets",
	EXPORT_TERMINAL_PENDING_BET_HISTORY: "/Bet/ExportTerminalPendingBets",
	EXPORT_TERMINAL_SETTLED_BET_HISTORY: "/Bet/ExportTerminalSettledBets",
	GET_TERMINAL_BETS_TOTALS: "/Bet/TerminalBetsTotals",

	AGGREGATED_BET_HISTORY: "/Bet/AggregatedBets",
	EXPORT_AGGREGATED_BET_HISTORY: "/Bet/ExportAggregatedBets",
	AGGREGATED_BETS_TOTALS: "/Bet/AggregatedBetsTotals",

	GET_ONLINE_BET_HISTORY: "/Bet/OnlineBets",
	EXPORT_ONLINE_BET_HISTORY: "/Bet/ExportOnlineBets",
	ONLINE_BETS_TOTALS: "/Bet/OnlineBetsTotals",

	GET_REAL_TIME_SPORT_BETS: "/Agent/playersSportBets",
	GET_REAL_TIME_SPORT_BET_DETAILS: "/Agent/PlayersBetDetails",
	EXPORT_REAL_TIME_SPORT_BETS: "/Agent/ExportPlayersSportBets",

	GET_REAL_TIME_CASINO_BETS: "/Agent/PlayersCasinoBets",
	EXPORT_REAL_TIME_CASINO_BETS: "/Agent/ExportPlayersCasinoBets",

	GET_BETSHOPS: "/BetShop/All",
	EXPORT_BETSHOPS: "/BetShop/Export",
	CREATE_BETSHOP: "/BetShop/Create",
	GET_BETSHOP_AVAILABLE_CURRENCIES: "/BetShop/AvailableCurrencies",
	GET_BETSHOP_GENERAL_INFO: "/BetShop/GeneralInfo",
	SAVE_BETSHOP_GENERAL_INFO: "/BetShop/SaveGeneralInfo",
	SAVE_BETSHOP_TRANSLATION: "/BetShop/SaveTranslation",
	GET_BETSHOP_CURRENCY: "/BetShop/GetCurrency",
	SAVE_BETSHOP_CURRENCY: "/BetShop/SaveCurrency",
	DELETE_BETSHOP_CURRENCY: "/BetShop/DeleteCurrency",
	GET_BETSHOP_LIMIT_SETTINGS: "/BetShop/LimitSettings",
	SAVE_BETSHOP_LIMIT_SETTINGS: "/BetShop/SaveLimitSettings",
	GET_BETSHOP_ADDITIONAL_ACCESS: "/BetShop/AdditionalAccesses",
	SAVE_BETSHOP_ADDITIONAL_ACCESS: "/BetShop/SaveAdditionalAccesses",
	GET_BETSHOP_IP_ACCESS_RULE: "/BetShop/IPAccessRule",
	ADD_BETSHOP_ALLOWED_IP: "/BetShop/AddAllowedIP",
	DELETE_BETSHOP_ALLOWED_IP: "/BetShop/DeleteAllowedIP",
	CHANGE_IP_ACCESS_RULE_STATE: "/BetShop/ChangeIPAccessRuleState",
	CHANGE_BETSHOP_WALLET: "/BetShop/ChangeWallet",
	GET_BETSHOP_TRANSACTIONS: "/BetShop/GetWalletHistory",
	CHANGE_BETSHOP_STATE: "/BetShop/ChangeState",
	REPARENT_BETSHOP: "/BetShop/Reparent",

	GET_CASHIERS: "/Cashier/All",
	EXPORT_CASHIERS: "/Cashier/Export",
	CREATE_CASHIER: "/Cashier/Create",
	GET_CASHIER_GENERAL_INFO: "/Cashier/GeneralInfo",
	SAVE_CASHIER_GENERAL_INFO: "/Cashier/SaveGeneralInfo",
	CHANGE_CASHIER_STATE: "/Cashier/ChangeState",
	FORCE_LOGOUT_CASHIER: "/Cashier/ForceLogout",
	GET_CASHIER_AVAILABLE_BETSHOPS: "/Cashier/AvailableBetshops",
	CHANGE_CASHIER_PASSWORD: "/Cashier/SetPassword",
	GET_CASHIER_ADDITIONAL_ACCESS: "/Cashier/AdditionalAccesses",
	SAVE_CASHIER_ADDITIONAL_ACCESS: "/Cashier/SaveAdditionalAccesses",

	GET_BETSHOP_MANAGERS: "/BetShopManager/All",
	EXPORT_BETSHOP_MANAGERS: "/BetShopManager/Export",
	CREATE_BETSHOP_MANAGER: "/BetShopManager/Create",
	GET_BETSHOP_MANAGER_GENERAL_INFO: "/BetShopManager/GeneralInfo",
	SAVE_BETSHOP_MANAGER_GENERAL_INFO: "/BetShopManager/SaveGeneralInfo",
	CHANGE_BETSHOP_MANAGER_STATE: "/BetShopManager/ChangeState",
	FORCE_LOGOUT_BETSHOP_MANAGER: "/BetShopManager/ForceLogout",
	GET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS: "/BetShopManager/AvailableBetshops",
	CHANGE_BETSHOP_MANAGER_PASSWORD: "/BetShopManager/SetPassword",

    GET_TERMINALS: "/Terminal/All",
	EXPORT_TERMINALS: "/Terminal/Export",
	CREATE_TERMINAL: "/Terminal/Create",
    GET_TERMINAL_GENERAL_INFO: "/Terminal/GeneralInfo",
    SAVE_TERMINAL_GENERAL_INFO: "/Terminal/SaveGeneralInfo",
    CHANGE_TERMINAL_STATE: "/Terminal/ChangeState",

    GET_TERMINAL_COLORS: "/TerminalUIBuilder/Colors",
    SAVE_TERMINAL_COLORS: "/TerminalUIBuilder/SaveColor",
    GET_TERMINAL_FONT_FAMILY: "/TerminalUIBuilder/FontFamily",
    SAVE_TERMINAL_FONT_FAMILY: "/TerminalUIBuilder/SaveFontFamily",
    UPLOAD_TERMINAL_IMAGE: "/TerminalUIBuilder/UploadImage",
    DELETE_TERMINAL_IMAGE: "/TerminalUIBuilder/DeleteImage",
    GET_TERMINAL_IMAGE: "/TerminalUIBuilder/GetImage",
    GET_TERMINAL_CONFIGURATION: "/TerminalUIBuilder/Configuration",
    PUBLISH_TERMINAL: "/TerminalUIBuilder/Publish",
    GET_TERMINAL_INFO: "/TerminalUIBuilder/Info",
    GET_TERMINAL_TRANSLATION_GROUPS: "/TerminalUIBuilder/TranslationGroups",
    GET_TERMINAL_SECTION_ITEMS: "/TerminalUIBuilder/SectionItems",
    REMOVE_TERMINAL_SECTION_ITEM: "/TerminalUIBuilder/RemoveItem",
    REORDER_TERMINAL_SECTION_ITEMS: "/TerminalUIBuilder/ReorderItem",
    CHANGE_TERMINAL_SECTION_ITEM_STATE: "/TerminalUIBuilder/ChangeItemState",
    ADD_TERMINAL_HEADER_ITEM: "/TerminalUIBuilder/AddHeaderItem",
    ADD_TERMINAL_FEATURED_PRODUCT: "/TerminalUIBuilder/AddFeaturedProductsItem",
    EDIT_TERMINAL_FEATURED_PRODUCT: "/TerminalUIBuilder/EditFeaturedProductsItem",
    ADD_TERMINAL_PROMOTION: "/TerminalUIBuilder/AddPromotionsItem",
    EDIT_TERMINAL_PROMOTION: "/TerminalUIBuilder/EditPromotionsItem",
    GET_TERMINAL_TERMS_AND_CONDITIONS: "/TerminalUIBuilder/TermsAndConditions",
    SAVE_TERMINAL_TERMS_AND_CONDITIONS: "/TerminalUIBuilder/SaveTermsAndConditions",
    GET_TERMINAL_LANGUAGES: "/TerminalUIBuilder/Languages",
	SAVE_TERMINAL_LANGUAGE: "/TerminalUIBuilder/SaveLanguage",
	SET_TERMINAL_DEFAULT_LANGUAGE: "/TerminalUIBuilder/SetLanguageDefault",
    SAVE_TERMINAL_AGE_RESTRICTION: "/TerminalUIBuilder/AddAgeRestriction",
    EDIT_TERMINAL_CERTIFICATE: "/TerminalUIBuilder/EditCertificate",
    ADD_TERMINAL_SECOND_SCREEN_BANNER: "/TerminalUIBuilder/AddSecondScreenBanner",
    EDIT_TERMINAL_SECOND_SCREEN_BANNER: "/TerminalUIBuilder/EditSecondScreenBanner",
    SAVE_TERMINAL_SECOND_SCREEN_SETTINGS: "/TerminalUIBuilder/EditSecondScreenSettings",

	GET_PROJECTS: "/Project/All",
	EXPORT_PROJECTS: "/Project/Export",
	CREATE_PROJECT: "/Project/Create",
	GET_PROJECT_GENERAL_INFO: "/Project/GeneralInfo",
	SAVE_PROJECT_GENERAL_INFO: "/Project/SaveGeneralInfo",
	GET_PROJECT_INTEGRATION: "/Project/Integration",
	SAVE_PROJECT_INTEGRATION: "/Project/SaveIntegration",
	GET_PROJECT_CHATUI: "/Project/ChatUI",
	SAVE_PROJECT_CHATUI: "/Project/SaveChatUI",
	GET_GAME_PROVIDERS: "/Project/GameProviders",
	UPLOAD_LOGO: "/Project/UploadLogo",
	DELETE_LOGO: "/Project/DeleteLogo",

	GET_PROJECT_BALANCE: "/Project/Balance",

	ADD_PROJECT_CURRENCY: "/Project/AddCurrency",
	SAVE_PROJECT_CURRENCY: "/Project/SaveCurrency",
	DELETE_PROJECT_CURRENCY: "/Project/DeleteCurrency",
	GET_PROJECT_CURRENCIES: "/Project/Currencies",
	GET_PROJECT_SUB_PROJECTS: "/Project/SubProjects",
	GET_PROJECT_AVAILABLE_CURRENCIES: "/Project/AvailableCurrencies",

	GET_PROJECT_AGENT_SYSTEM_NETWORK: "/Project/Network",
	ADD_PROJECT_AGENT_SYSTEM_NETWORK_LAYER: "/Project/AddNetworkLayer",
	DELETE_PROJECT_AGENT_SYSTEM_NETWORK_LAYER: "/Project/DeleteNetworkLayer",
	CHANGE_PROJECT_AGENT_SYSTEM_NETWORK_LABEL: "/Project/ChangeNetworkLabel",
	GET_PROJECT_AGENT_REGISTRATION_FORM: "/Project/AgentRegistrationForm",
	SAVE_PROJECT_AGENT_REGISTRATION_FORM: "/Project/SaveAgentRegistrationForm",
	GET_PROJECT_AGENT_ADMIN_FEE: "/Project/AdmistrativeFees",
	SAVE_PROJECT_AGENT_ADMIN_FEE: "/Project/SaveAdmistrativeFees",
	GET_PROJECT_PLAYER_REGISTRATION_FORM: "/Project/PlayerRegistrationForm",
	SAVE_PROJECT_PLAYER_REGISTRATION_FORM: "/Project/SavePlayerRegistrationForm",
	GET_PROJECT_LIMITS: "/Project/Limits",
	SAVE_PROJECT_LIMITS: "/Project/SaveLimits",
	GET_PROJECT_VS_BET_LIMITS: "/Project/VsBetLimits",
	SAVE_PROJECT_VS_BET_LIMITS: "/Project/SaveVSBetLimits",
    GET_PROJECT_FAVORITE_AMOUNTS: "/Project/TransactionFavoriteAmounts",
	SAVE_PROJECT_FAVORITE_AMOUNTS: "/Project/SaveTransactionFavoriteAmounts",
	GET_PROJECT_RETAIL_PROVIDER_SETTINGS: "/Project/RetailProvidersSettings",
	SAVE_PROJECT_RETAIL_PROVIDER_SETTINGS: "/Project/SaveRetailProvidersSettings",
    GET_PROJECT_TERMINAL_PROVIDER_SETTINGS: "/Project/TerminalProvidersSettings",
	SAVE_PROJECT_TERMINAL_PROVIDER_SETTINGS: "/Project/SaveTerminalProvidersSettings",
	GET_PROJECT_CONFIGS: "/Project/Regulation",
	SAVE_PROJECT_CONFIGS: "/Project/SaveRegulation",
    GET_PROJECT_VOUCHERS_CONFIGS: "/Project/VouchersConfigs",
	SAVE_PROJECT_VOUCHERS_CONFIGS: "/Project/SaveVouchersConfigs",
    GET_PROJECT_TERMINAL_SETTINGS: "/Project/TerminalSettings",
	SAVE_PROJECT_TERMINAL_SETTINGS: "/Project/SaveTerminalSettings",
    GET_PROJECT_TERMINAL_INFO: "/Project/TerminalInfo",
	GET_VOUCHER_TICKET: "/Project/VoucherTicket",
	SAVE_VOUCHER_TICKET: "/Project/SaveVoucherTicket",
	UPLOAD_VOUCHER_TICKET_LOGO: "/Project/UploadVoucherTicketLogo",
	DELETE_VOUCHER_LOGO: "/Project/DeleteVoucherTicketLogo",

	GET_PROJECT_SMS_PROVIDERS: "/SMSProvider/Providers",
	GET_PROJECT_SMS_PROVIDER: "/SMSProvider/ProjectProvider",
	SAVE_PROJECT_SMS_PROVIDER: "/SMSProvider/SaveProjectProvider",
	SEND_TEST_SMS: "/SMSProvider/SendTest",

	GET_PROJECT_PAYMENT_SETTINGS: "/PaymentSettings/ProjectSettings",
	SAVE_PROJECT_PAYMENT_SETTINGS: "/PaymentSettings/SaveProjectSettings",
	GET_PROJECT_BANKS: "/PaymentSettings/ProjectBanks",
	GET_PROJECT_BANK_GENERAL_INFO: "/PaymentSettings/ProjectBankGeneralInfo",
	SAVE_PROJECT_BANK_NAME_TRANSLATION: "/PaymentSettings/SaveBankNameTranslation",
	SAVE_PROJECT_BANK_GENERAL_INFO: "/PaymentSettings/SaveProjectBankGeneralInfo",
	CREATE_PROJECT_BANK: "/PaymentSettings/CreateBank",
	DEACTIVATE_PROJECT_BANK: "/PaymentSettings/DeactivateProjectBank",
	ACTIVATE_PROJECT_BANK: "/PaymentSettings/ActivateProjectBank",
	DELETE_PROJECT_BANK: "/PaymentSettings/DeleteProjectBank",
	GET_PROJECT_BANK_FORM: "/PaymentSettings/BankForm",
	SAVE_PROJECT_BANK_FORM: "/PaymentSettings/SaveBankForm",
	SAVE_PROJECT_BANK_FORM_TRANSLATION: "/PaymentSettings/SaveBankFormTranslation",
	LAUNCH_PROJECT_WIDGET: "/PaymentSettings/LaunchProjectWidget",
	CHANGE_BANK_LOGO: "/PaymentSettings/ChangeBankLogo",
	CHANGE_BANKS_ORDER: "/PaymentSettings/SaveProjectBanksOrder",
	GET_PAYOUT_TICKET: "/Project/PayoutTicket",
	SAVE_PAYOUT_TICKET: "/Project/SavePayoutTicket",
	UPLOAD_PAYOUT_TICKET_LOGO: "/Project/UploadTicketLogo",
	DELETE_PAYOUT_TICKET_LOGO: "/Project/DeleteTicketLogo",

	GET_AGENT_PAYMENT_SETTINGS: "/PaymentSettings/AgentSettings",
	SAVE_AGENT_PAYMENT_SETTINGS: "/PaymentSettings/SaveAgentSettings",
	GET_EPOS_SETTINGS: "/PaymentSettings/EposSettings",
	SAVE_EPOS_TRANSLATIONS: "/PaymentSettings/SaveEposTranslation",
	SAVE_EPOS_SETTINGS: "/PaymentSettings/SaveEposSettings",
	GET_AGENT_BANKS: "/PaymentSettings/AgentBanks",
	GET_AGENT_BANK_GENERAL_INFO: "/PaymentSettings/AgentBankGeneralInfo",
	ADD_AGENT_BANK: "/PaymentSettings/AddBank",
	DEACTIVATE_AGENT_BANK: "/PaymentSettings/DeactivateAgentBank",
	ACTIVATE_AGENT_BANK: "/PaymentSettings/ActivateAgentBank",
	DELETE_AGENT_BANK: "/PaymentSettings/DeleteAgentBank",
	GET_AGENT_AVAILABLE_BANKS: "/PaymentSettings/AvailableBanks",
	GET_AGENT_BANK_DETAILS: "/PaymentSettings/BankDetails",
	ADD_AGENT_BANK_DETAILS: "/PaymentSettings/AddBankDetails",
	ADD_AGENT_BANK_IMAGE: "/PaymentSettings/AddBankimage",
	DELETE_AGENT_BANK_DETAILS: "/PaymentSettings/DeleteBankDetails",
	SAVE_AGENT_BANK_DETAILS: "/PaymentSettings/SaveBankDetailsTranslation",
	LAUNCH_AGENT_WIDGET: "/PaymentSettings/LaunchAgentWidget",

	GET_BANK_TRANSLATIONS: "/PaymentSettings/BankFormTranslations",

	GET_ADMINS: "/User/All",
	DELETE_USER: "/User/Delete",
	EXPORT_ADMINS: "/User/Export",
	CREATE_ADMIN: "/User/Create",
	GET_USER_GENERAL_INFO: "/User/GeneralInfo",
	SAVE_USER_GENERAL_INFO: "/User/SaveGeneralInfo",
	GET_USER_AVAILABLE_COMPANIES: "/User/AvailableCompanies",
	GET_USER_ADDITIONAL_ACCESSES: "/User/AdditionalAccesses",
	SAVE_USER_ADDITIONAL_ACCESSES: "/User/SaveAdditionalAccesses",
	GET_USER_COMPANY_ACCESS: "/User/CompanyAccess",
	ADD_USER_COMPANY_ACCESS: "/User/AddCompanyAccess",
	DELETE_USER_COMPANY_ACCESS: "/User/DeleteCompanyAccess",

	GET_ACCESS_MANAGERS: "/AccessManager/All",
	EXPORT_ACCESS_MANAGERS: "/AccessManager/Export",
	CREATE_ACCESS_MANAGER: "/AccessManager/Create",
	GET_ACCESS_MANAGER_GENERAL_INFO: "/AccessManager/GeneralInfo",
	SAVE_ACCESS_MANAGER_GENERAL_INFO: "/AccessManager/SaveGeneralInfo",

	GET_SESSIONS: "/Session/All",
	EXPORT_SESSIONS: "/Session/Export",

	GET_NOTIFICATIONS: "/Notification/Notifications",
	MARK_AS_READ_NOTIFICATION: "/Notification/MarkAsRead",
	MARK_ALL_AS_READ_NOTIFICATIONS: "/Notification/MarkAllAsRead",
	DELETE_NOTIFICATION: "/Notification/DeleteNotification",
	DELETE_ALL_NOTIFICATIONS: "/Notification/DeleteAllNotifications",

	GET_TRANSLATION_GROUPS: "/Translation/AllGroups",
	SAVE_TRANSLATION: "/Translation/Save",
	SAVE_BO_TRANSLATION: "/Translation/SaveBOTranslations",
	SAVE_WIDGET_TRANSLATION: "/Translation/SaveWidgetTranslations",
    SAVE_AGENT_TRANSLATION: "/Translation/SaveAgentTranslations",
    SAVE_TERMINAL_TRANSLATION: "/Translation/SaveTerminalTranslations",
	CREATE_TRANSLATION: "/Translation/Create",
	RESET_TRANSLATION: "/Translation/Reset",
	GET_TRANSLATIONS: "/Translation/All",
	GET_BO_TRANSLATIONS: "/Translation/BOTranslations",
	GET_WIDGET_TRANSLATIONS: "/Translation/WidgetTranslations",
    GET_AGENT_TRANSLATIONS: "/Translation/AgentTranslations",
    GET_TERMINAL_TRANSLATIONS: "/Translation/TerminalTranslations",
	PUBLISH_TRANSLATIONS: "/Translation/Publish",
	PUBLISH_BO_TRANSLATIONS: "/Translation/PublishBOTranslations",
	PUBLISH_WIDGET_TRANSLATIONS: "/Translation/PublishWidgetTranslations",
    PUBLISH_AGENT_TRANSLATIONS: "/Translation/PublishAgentTranslations",
    PUBLISH_TERMINAL_TRANSLATIONS: "/Translation/PublishTerminalTranslations",
	UNPUBLISH_TRANSLATIONS: "/Translation/Unpublish",
	UNPUBLISH_WIDGET_TRANSLATIONS: "/Translation/UnpublishWidgetTranslations",

	GET_SYSTEM_PERMISSIONS: "/Permission/SystemPermissions",
	GET_SYSTEM_PERMISSION_RESOURCES: "/Permission/SystemResources",
	GET_PERMISSION_GROUPS: "/Permission/AllGroups",
	CREATE_PERMISSION_GROUP: "/Permission/CreateGroup",
	DELETE_PERMISSION_GROUP: "/Permission/DeleteGroup",
	GET_PERMISSION_GROUP_PERMISSIONS: "/Permission/GroupPermissions",
	SAVE_PERMISSION_GROUP_PERMISSIONS: "/Permission/SaveGroupPermissions",
	GET_PERMISSION_GROUP_USERS: "/Permission/GroupUsers",
	ADD_PERMISSION_GROUP_USERS: "/Permission/AddUsersPermissionGroup",
	DELETE_PERMISSION_GROUP_USER: "/Permission/DeleteUserPermissionGroup",
	GET_USER_PERMISSION_GROUPS: "/Permission/UserGroups",
	ADD_USER_PERMISSION_GROUP: "/Permission/AddUserPermissionGroup",
	GET_USER_AVAILABLE_PERMISSION_GROUPS: "/Permission/AvailableGroups",
	GET_USER_PERMISSIONS: "/Permission/UserPermissions",
	SAVE_USER_PERMISSIONS: "/Permission/SaveUserPermissions",
	SET_DEFAULT: "/Permission/SetDefault",
	RESET_DEFAULT: "/Permission/ResetDefault",

	GET_PERMISSION_REQUESTS: "/Permission/Requests",
	APPROVE_PERMISSION_REQUEST: "/Permission/Approve",
	REJECT_PERMISSION_REQUEST: "/Permission/Reject",
	GET_PERMISSION_REQUESTS_HISTORY: "/Permission/RequestHistory",

	GET_PLATFORMS: "/Platform/Platforms",
	TEST_PLATFORM: "/Platform/Test",

	GET_FAILED_TRANSACTIONS: "/FailedTransaction/All",
	EXPORT_FAILED_TRANSACTIONS: "/FailedTransaction/Export",
	RESEND_FAILED_TRANSACTIONS: "/FailedTransaction/ResendFailedTransaction",

	GET_USER_LOGS: "/UserLogs/All",
	EXPORT_USER_LOGS: "/UserLogs/Export",
	GET_USER_LOG_DETAILS: "/UserLogs/Details",
	GET_USER_LOG_RESOURCES: "/UserLogs/Resources",

	GET_ERRORS: "/SystemLogs/Exceptions",
	GET_INTEGRATION_LOGS: "/SystemLogs/IntegrationLogs",
	GET_GENERATIONS: "/Report/PlatformGenerationsReport",

	GET_JOBS: "/Jobs/All",
	UNLOCK_JOB: "/Jobs/Unlock",
	GET_JOB_SETTINGS: "/Jobs/GetSettings",
	SAVE_JOB_SETTINGS: "/Jobs/SaveSettings",
	GET_JOB_DETAILS: "/Jobs/CurrentlyExecutingJob",

	GET_MONITORING_ALL: "/SystemCounter/GetAll",
	GET_MONITORING: "/SystemCounter/Get",

	CALCULATE_PLAYERS_PERFORMANCE: "/Developer/CalculatePlayersPerformance",
	CALCULATE_AGENTS_PERFORMANCE: "/Developer/CalculateAgentsPerformance",
	CALCULATE_BETSHOPS_PERFORMANCE: "/Developer/CalculateBetShopsPerformance",
	CALCULATE_DASHBOARD_OPERATIONS: "/Developer/CalculateOperations",
    CALCULATE_CATEGORIES_PERFORMANCE: "/Developer/CalculateCategoriesPerformance",

	UPDATE_TRANSLATIONS: "/Developer/UpdateTranslations",
	FLUSH_CACHE: "/Developer/FlushCache",
	LANGUAGES: "/Developer/Languages",
	EXPORT_TRANSLATIONS: "/Developer/ExportTranslations",

	GET_DB_CONNECTIONS: "/Developer/DBConnections",
	KILL_DB_CONNECTIONS: "/Developer/KillConnection",

	AUTOSUGGESTION_GET_BETSHOPS: "/Autosuggestion/BetShops",
	AUTOSUGGESTION_GET_CASHIERS: "/Autosuggestion/Cashiers",
	AUTOSUGGESTION_GET_BETSHOP_MANAGERS: "/Autosuggestion/BetShopManagers",
    AUTOSUGGESTION_GET_TERMINALS: "/Autosuggestion/Terminals",
	AUTOSUGGESTION_GET_PROJECTS: "/Autosuggestion/Projects",
	AUTOSUGGESTION_GET_COMPANIES: "/Autosuggestion/Companies",
	AUTOSUGGESTION_GET_USERS: "/Autosuggestion/Users",
	AUTOSUGGESTION_GET_ACCESS_MANAGERS: "/Autosuggestion/AccessManagers",
	AUTOSUGGESTION_GET_AGENTS: "/Autosuggestion/SubAgents",
	AUTOSUGGESTION_GET_PLAYERS: "/Autosuggestion/Players",
	AUTOSUGGESTION_GET_PERMISSION_GROUPS: "/Autosuggestion/PermissionGroups",
	AUTOSUGGESTION_GET_CONTROLLERS: "/Autosuggestion/Controllers",
	AUTOSUGGESTION_GET_BANKS: "/Autosuggestion/Banks",
	AUTOSUGGESTION_GET_COMMISSION_PLANS: "/Autosuggestion/Commissions",
	AUTOSUGGESTION_GET_PERMISSION_GROUP_AVAILABLE_USERS: "/Autosuggestion/PermissionGroupAvailableUsers",
	AUTOSUGGESTION_GET_PLAYERS_TOP: "/Autosuggestion/PlayersTop",

	GET_SYSTEM_TIMEZONES: "/System/TimeZones",
	GET_SYSTEM_COUNTRIES: "/System/Countries",

	GET_PAYMENT_DEPOSIT_REQUESTS: "/Transaction/TransferDepositRequests",
	GET_PAYMENT_WITHDRAW_REQUESTS: "/Transaction/TransferWithdrawRequests",
	EXPORT_PAYMENT_DEPOSIT_REQUESTS: "/Transaction/ExportTransferDepositRequests",
	EXPORT_PAYMENT_WITHDRAW_REQUESTS: "/Transaction/ExportTransferWithdrawRequests",

	GET_PAYMENT_DEPOSIT_HISTORY: "/Transaction/DepositHistory",
	GET_PAYMENT_WITHDRAW_HISTORY: "/Transaction/WithdrawHistory",
	EXPORT_PAYMENT_DEPOSIT_HISTORY: "/Transaction/ExportDepositHistory",
	EXPORT_PAYMENT_WITHDRAW_HISTORY: "/Transaction/ExportWithdrawHistory",

	GET_WIDGET_TRANSLATION_PATH: "/Widget/TranslationsPath",
}

export default PATHS;
