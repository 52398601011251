//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region actions
import {
    setTerminalPendingBetHistorySorting,
    setTerminalPendingBetHistoryFilters,
    getTerminalPendingBetHistory
} from 'store/actions/dashboard/betHistory/terminalPendings.action';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import BetDetails from "../../betDetails.component";
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { getDisabledExpandRowIds } from 'utils/bet';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { TERMINAL_PENDING_BETS } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
import { SEARCH_TYPE } from 'components/common/search/constants';
//#endregion

//#region types
import betType from 'types/bet/bet.type';
import sortingType from 'types/common/sorting.type';
//#endregion

/** Terminal Pending Bet History Component */
const PendingBetHistoryComponent = ({
    bets,
    sorting,
    filters,
    total,
    isLoading,
    getTerminalPendingBetHistory,
    setTerminalPendingBetHistorySorting,
    setTerminalPendingBetHistoryFilters,
    globalProjectId,
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: TERMINAL_PENDING_BETS });

    const disabledExpandRowsIds = getDisabledExpandRowIds(bets);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_RETAIL,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setTerminalPendingBetHistoryFilters({
            ...filters,
            betSlipId: value
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount
            }
        })
    }, [includedColumns, formatAmount]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            type: SEARCH_TYPE.NUMERIC,
            loadFn: getTerminalPendingBetHistory,
            numeric: true,
            maxLength: 12,
            onSearch: handleSearchChange,
            placeholder: t("backoffice.bets.searchByBetslipId")
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.bets.pending"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_TERMINAL_PENDING_BET_HISTORY,
            filters: filters
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={bets}
                loadFn={getTerminalPendingBetHistory}
                sorting={sorting}
                setSortingFn={setTerminalPendingBetHistorySorting}
                filters={filters}
                setFiltersFn={setTerminalPendingBetHistoryFilters}
                total={total}
                updateProps={[globalProjectId]}
                enableReload={true}
                expandable={{
                    title: t("backoffice.bets.bets"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    disabled: disabledExpandRowsIds,
                    details: record => Boolean(record.bets) && ({
                        data: record.bets,
                        content: (
                            <BetDetails
                                bets={record.bets}
                                currencyCode={record.currencyCode}
                            />
                        ),
                    }),
                }}

            />
        </TabTableDashboardLayout>
    )
}

/** PendingBetHistoryComponent propTypes
    * PropTypes
*/
PendingBetHistoryComponent.propTypes = {
    /** Redux state property, represents the array of terminal pending bet history */
    bets: PropTypes.arrayOf(betType),
    /** Redux state property, terminal pending bet history sorting details */
    sorting: sortingType,
    /** Redux state property, terminal pending bet history filters */
    filters: PropTypes.object,
    /** Redux state property, terminal pending bet history total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading bet history */
    isLoading: PropTypes.bool,
    /** Redux action to get terminal pending bet history */
    getTerminalPendingBetHistory: PropTypes.func,
    /** Redux action to set terminal pending bet history sorting details */
    setTerminalPendingBetHistorySorting: PropTypes.func,
    /** Redux action to set terminal pending bet history filters */
    setTerminalPendingBetHistoryFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getTerminalPendingBetHistory: nextPage => {
            dispatch(getTerminalPendingBetHistory(nextPage))
        },
        setTerminalPendingBetHistorySorting: sorting => {
            dispatch(setTerminalPendingBetHistorySorting(sorting));
        },
        setTerminalPendingBetHistoryFilters: (filters, keepPage) => {
            dispatch(setTerminalPendingBetHistoryFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        bets: state.betHistory.terminal.pendings.bets,
        total: state.betHistory.terminal.pendings.total,
        sorting: state.betHistory.terminal.pendings.sorting,
        filters: state.betHistory.terminal.pendings.filters,
        isLoading: state.betHistory.isLoading,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingBetHistoryComponent)
