import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_CURRENCIES,
    UPDATE_CURRENCIES,
    SET_USER_CURRENCY
} from "../../../actionTypes";

import { changeCurrency } from "utils/auth";

const setCurrencies = currencies => ({
    type: SET_CURRENCIES,
    payload: { currencies }
})

export const getCurrencies = () => {
    return ( dispatch, getState) => {
        return axios({
            url: ApiUrls.GET_CURRENCIES,
            method: Methods.GET
        })
        .then(({data : {value: {item2: currencies}}}) => {
            const selectedCurrency = getState().profile.currency;
            if(!selectedCurrency || !currencies.some(c => c.currencyCode === selectedCurrency)){
                const defaultCurrency = currencies.find(c => c.currency?.isDefault) ?? currencies[0];
                if(defaultCurrency){
                    changeCurrency(defaultCurrency.currencyCode);
                }
            }

            dispatch(setCurrencies(currencies))
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}

export const updateCurrencies = ({ type: walletType, amount, currencyCode }) => ({
    type: UPDATE_CURRENCIES,
    payload: { walletType, amount, currencyCode }
})

export const setUserCurrency = currency => ({
    type: SET_USER_CURRENCY,
    payload: { currency }
})
