const getModalDefaultState = () => ({
    opened: false,
    title: "",
    okText: "",
    message: "",
    loading: false,
    onOk: () => {},
})

export default getModalDefaultState;
