import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Divider } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import Input from 'components/common/input';
import DateRangePicker from "components/common/dateRangePicker";

import { getCashierTransactionsReport, setCashierTransactionsReportFilters } from "store/actions/dashboard/reports/cashierTransactions.action";

import { makeTransactionText } from "utils/wallet"

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useProjectType from 'hooks/useProjectType';

/** Cashier Transaction Report Page FiltersComponent */

const Filters = ({
    setCashierTransactionsReportFilters,
    getCashierTransactionsReport,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const { hasTerminal } = useProjectType();

    const [ cashierNames, getCashierNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CASHIER,
        autoGet: false
    });
    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: false
    });

    const availableOperationTypes = useMemo(() => {
        const availableOperationTypes = [
            WALLET_OPERATION_TYPE.BET,
            WALLET_OPERATION_TYPE.CANCEL_BET,
            WALLET_OPERATION_TYPE.PAIDOUT,
            WALLET_OPERATION_TYPE.WON,
            WALLET_OPERATION_TYPE.REJECT_BET,
            WALLET_OPERATION_TYPE.RECALCULATE,
            WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BETSHOP,
            WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP,
            WALLET_OPERATION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER
        ];

        
        if(hasTerminal){
            availableOperationTypes.push(WALLET_OPERATION_TYPE.ISSUE_VOUCHER, WALLET_OPERATION_TYPE.PAYOUT_VOUCHER)
        }

        return availableOperationTypes;
    }, [hasTerminal])


    return (
        <FiltersWrapper
            loadFn={getCashierTransactionsReport}
            setFiltersFn={setCashierTransactionsReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName="groupId"
            onInit={() => {
                getCashierNames();
                getBetshopNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.transactionType')}
                        name="transactionTypes"
                    >
                        <Select
                            options={
                                availableOperationTypes.map(type => (
                                    { value: type, text: makeTransactionText(type) }
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.transactionType')}`}
                            isMultiple={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                    <Divider className='rt--filters-divider' />
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.betshop')}
                        name="betShopId"
                    >
                        <Select
                            options={
                                [
                                    { value: null, text: t("backoffice.common.all") },
                                    ...betshopNames.map(b => (
                                        { value: b.id, text: b.name }
                                    ))
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.betshop')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.cashierUsernameOrId')}
                        name="cashier"
                    >
                        <AutoComplete
                            placeholder={
                                `${t('backoffice.common.enter')} ${t('backoffice.reports.cashierUsernameOrId')}`}
                            items={cashierNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>



                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.playerNameOrId')}
                        name="player"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.playerNameOrId')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.playerNameOrId')}`}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get cashier transactions report */
    getCashierTransactionsReport: PropTypes.func,
    /** Redux action to set cashier transactions report sorting details */
    setCashierTransactionsReportFilters: PropTypes.func,
    /** Redux state property, errors filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getCashierTransactionsReport: nextPage => {
            dispatch(getCashierTransactionsReport(nextPage));
        },
        setCashierTransactionsReportFilters: filters => {
            dispatch(setCashierTransactionsReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.cashierTransactionsReport.filters,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
