import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { Version } from 'utils/version';

import { USER_ROLE } from "constants/user.constants";

import { TRANSACTION_SOURCE, TRANSACTION_PAYMENT_SOURCE, TRANSACTION_AMOUNT_ACTION_TYPE } from "../constants";

/** ======================== Betshops ======================== */

export const getBetshops = async agentId => {
    const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_BETSHOPS,
        method: Methods.GET,
        params: {
            agentId
        }
    })

    return response?.data?.value;
}

export const getBetshopCurrency = async id => {
    const response = await axios({
        url: ApiUrls.GET_BETSHOP_GENERAL_INFO,
        method: Methods.GET,
        params: { id }
    })

    return response?.data?.value?.currencyCode;
}

export const betShopDeposit = async data => {
    const response = await axios({
        url: ApiUrls.BETSHOP_DEPOSIIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

export const betShopWithdrawal = async data => {
    const response = await axios({
        url: ApiUrls.BETSHOP_WITHDRAWAL,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

export const betShopLimitAdjustment = async data => {
    const response = await axios({
        url: ApiUrls.BETSHOP_LIMIT_ADJUSTMENT,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

export const resetBetShopLimit = async data => {
    const response = await axios({
        url: ApiUrls.RESET_BETSHOP_LIMIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

/** ======================== Agents ======================== */

export const getAgents = async params => {
    const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_AGENTS,
        method: Methods.GET,
        params: {
            ...params,
            excludeAgent: true
        }
    })

    return response?.data?.value;
}

export const getAgentCurrencies = async id => {
    const response = await axios({
        url: ApiUrls.GET_CURRENCIES,
        method: Methods.GET,
        params: {
            id
        }
    })

    return response?.data?.value.item2;
}

/** Deposit */

const agentDepositFromBalance = async data => {
    return axios({
        url: ApiUrls.AGENT_DEPOSIT_FROM_BALANCE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentDepositToCreditLine = async data => {
    return axios({
        url: ApiUrls.AGENT_DEPOSIT_TO_CREDIT_LINE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentDepositFromBalancePayDebt = async data => {
    return axios({
        url: ApiUrls.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentDepositFromCredit = async data => {
    return axios({
        url: ApiUrls.AGENT_DEPOSIT_FROM_CREDIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentDepositFromCreditPayDebt = async data => {
    return axios({
        url: ApiUrls.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const agentDeposit = async (data, additionalData) => {
    const { isWalletConfigNonRelated, franchisingMode, payDebt, transactionSource} = additionalData;

    let response = null;

    if(isWalletConfigNonRelated){
        if(transactionSource === TRANSACTION_SOURCE.FROM_BALANCE){
            response = await agentDepositFromBalance(data);
        } else {
            response = await agentDepositToCreditLine(data);
        }
    } else {
        if(transactionSource === TRANSACTION_SOURCE.BY_CASH){
            if(payDebt){
                response = await agentDepositFromCreditPayDebt(data);
            } else {
                response = await agentDepositFromCredit(data);
            }
        } else {
            if(payDebt || franchisingMode){
                response = await agentDepositFromBalancePayDebt(data);
            } else {
                response = await agentDepositFromBalance(data);
            }
        }
    }

    return response?.data;
}

/** Withdrawal */

const agentWithdrawalFromBalance = async data => {
    return axios({
        url: ApiUrls.AGENT_WITHDRAWAL_FROM_BALANCE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentWithdrawalFromCreditLine = async data => {
    return axios({
        url: ApiUrls.AGENT_WITHDRAW_FROM_CREDIT_LINE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentWithdrawalFromBalancePayDebt = async data => {
    return axios({
        url: ApiUrls.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentWithdrawalFromCredit = async data => {
    return axios({
        url: ApiUrls.AGENT_WITHDRAWAL_FROM_CREDIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentWithdrawalFromCreditPayDebt = async data => {
    return axios({
        url: ApiUrls.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const agentWithdrawal = async (data, additionalData) => {
    const { isWalletConfigNonRelated, franchisingMode, payDebt, transactionSource} = additionalData;

    let response = null;

    if(isWalletConfigNonRelated){
        if(transactionSource === TRANSACTION_SOURCE.FROM_BALANCE){
            response = await agentWithdrawalFromBalance(data);
        } else {
            response = await agentWithdrawalFromCreditLine(data);
        }
    } else {
        if(transactionSource === TRANSACTION_SOURCE.BY_CASH){
            if(payDebt || franchisingMode){
                response = await agentWithdrawalFromCreditPayDebt(data);
            } else {
                response = await agentWithdrawalFromCredit(data);
            }
        } else {
            if(payDebt || franchisingMode){
                response = await agentWithdrawalFromBalancePayDebt(data);
            } else {
                response = await agentWithdrawalFromBalance(data);
            }
        }
    }

    return response?.data;
}

/** Give Credit */

export const agentGiveCredit = async data => {
    const response = await axios({
        url: ApiUrls.AGENT_GIVE_CREDIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

/** Pay Dept */

const agentPayDebt = async data => {
    return axios({
        url: ApiUrls.AGENT_PAY_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const agentPayDebtWithDebt = async data => {
    return axios({
        url: ApiUrls.AGENT_PAY_DEBT_WITH_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const superAgentPayDebt = async data => {
    return axios({
        url: ApiUrls.SUPER_AGENT_PAY_DEBT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const agentDebt = async (data, additionalData) => {
    const { transactionSource, paymentSource, userRole } = additionalData;

    let response = null;

    if(userRole === USER_ROLE.AGENT){
        if(
            transactionSource === TRANSACTION_PAYMENT_SOURCE.FROM_BALANCE &&
            paymentSource === TRANSACTION_PAYMENT_SOURCE.FROM_CREDIT
        ){
            response = await agentPayDebtWithDebt(data);
        } else {
            response = await agentPayDebt(data);
        }
    } else {
        response = await superAgentPayDebt(data);
    }

    return response?.data;
}


/** ======================== Players ======================== */

export const getPlayerInfo = async id => {
    const response = await axios({
        url: ApiUrls.GET_PLAYER_GENERAL_INFO,
        method: Methods.GET,
        params: { id }
    })

    return response?.data?.value;
}

export const playerDeposit = async data => {
    const response = await axios({
        url: ApiUrls.PLAYER_DEPOSIIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

export const playerWithdrawal = async data => {
    const response = await axios({
        url: ApiUrls.PLAYER_WITHDRAWAL,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}


/** ======================== SuperAgent ======================== */

export const getProjectCurrencies = async () => {
    const response = await axios({
        url: ApiUrls.GET_PROJECT_CURRENCIES,
        method: Methods.GET,
        params: {}
    })

    return response?.data?.value?.currencies;
}

const depositProjectBalance = async data => {
    return axios({
        url: ApiUrls.PROJECT_BALANCE_DEPOSIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const withdrawProjectBalance = async data => {
    return axios({
        url: ApiUrls.PROJECT_BALANCE_WITHDRAW,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const adjustBalance = async (data, amountActionType) => {

    let response = null;

    if( amountActionType === TRANSACTION_AMOUNT_ACTION_TYPE.ADD){
        response = await depositProjectBalance(data);
    } else {
        response = await withdrawProjectBalance(data);
    }

    return response?.data;
}

/** Transaction */

export const rollbackTransaction = async data => {
    const response = await axios({
        url: ApiUrls.WALLET_ROLLBACK_TRANSACTION,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

const confirmTransactionRequestFreeze = async data => {
    return axios({
        url: ApiUrls.CONFIRM_TRANSACTION_REQUEST_FREEZE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const confirmTransactionRequestWithoutFreeze = async data => {
    return axios({
        url: ApiUrls.CONFIRM_TRANSACTION_REQUEST,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const confirmTransactionRequest = async (data, platVersion) => {
    const defaultVersion = new Version("v1.0");
    const platformVersionInstance = new Version(platVersion);

    let response = null;

    if(defaultVersion.isLessThan(platformVersionInstance)){
        response = await confirmTransactionRequestFreeze(data);
    } else {
        response = await confirmTransactionRequestWithoutFreeze(data);
    }

    return response?.data;
}

const rejectTransactionRequestFreeze = async data => {
    return axios({
        url: ApiUrls.REJECT_TRANSACTION_REQUEST_FREEZE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const rejectTransactionRequestWithoutFreeze = async data => {
    return axios({
        url: ApiUrls.REJECT_TRANSACTION_REQUEST,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const rejectTransactionRequest = async (data, platVersion) => {
    const defaultVersion = new Version("v1.0");
    const platformVersionInstance = new Version(platVersion);

    let response = null;

    if(defaultVersion.isLessThan(platformVersionInstance)){
        response = await rejectTransactionRequestFreeze(data);
    } else {
        response = await rejectTransactionRequestWithoutFreeze(data);
    }

    return response?.data;
}

export const adjustTransactionRequest = async data => {
    const response = await axios({
        url: ApiUrls.ADJUST_TRANSACTION_REQUEST,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}


/** Epos */

export const getPlayerInfoByExternalId = async params => {
    const response = await axios({
        url: ApiUrls.GET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
        method: Methods.GET,
        params: {
            ...params
        }
    })

    return response?.data?.value;
}

export const getPlayerWithdrawal = async params => {
    const response = await axios({
        url: ApiUrls.GET_EPOS_REQUEST,
        method: Methods.GET,
        params: {
            ...params
        }
    })

    return response?.data?.value;
}


export const playerEposDeposit = async data => {
    const response = await axios({
        url: ApiUrls.PLAYER_EPOS_DEPOSIT,
        method: Methods.POST,
        data: {
            ...data
        }
    })

    return response?.data;
}

const playerEposWithdrawalFreeze = async data => {
    return axios({
        url: ApiUrls.PLAYER_EPOS_WITHDRAWAL_FREEZE,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

const playerEposWithdrawalWithoutFreeze = async data => {
    return axios({
        url: ApiUrls.PLAYER_EPOS_WITHDRAWAL,
        method: Methods.POST,
        data: {
            ...data
        }
    })
}

export const playerEposWithdrawal = async (data, platVersion) => {
    const defaultVersion = new Version("v1.0");
    const platformVersionInstance = new Version(platVersion);

    let response = null;

    if(defaultVersion.isLessThan(platformVersionInstance)){
        response = await playerEposWithdrawalFreeze(data);
    } else {
        response = await playerEposWithdrawalWithoutFreeze(data);
    }

    return response?.data;
}
