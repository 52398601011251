import React from 'react';

import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Paths from 'constants/path.constants';

//#region Layouts
import RootLayout from "layouts/root";
import DashboardLayoutRoute from 'layouts/dashboard';
import MainLayoutRoute from 'layouts/dashboard/main';
import CustomizeLayoutRoute from 'layouts/dashboard/customize';
//#endregion

//#region Routes
import DashboardRoute from 'routes/dashboard/dashboard.route';
import ProfileRoute from 'routes/dashboard/profile.route';
import DashboardChartsRoute from "routes/dashboard/dashboardCharts.route";
import CompaniesRoute from 'routes/dashboard/companies.route';
import CompaniesEditRoute from "routes/dashboard/companiesEdit.route";
import ProjectsRoute from 'routes/dashboard/projects.route';
import ProjectsEditRoute from "routes/dashboard/projectsEdit.route";
import AgentTransactionsReportRoute from "routes/dashboard/agentTransactionsReport.route";
import CashierTransactionsReportRoute from "routes/dashboard/cashierTransactionsReport.route";
import BetshopTransactionsReportRoute from "routes/dashboard/betshopTransactionsReport.route";
import CommissionCalculationsReportRoute from "routes/dashboard/commissionCalculationsReport.route";
import AgentCalculationHistoryRoute from "routes/dashboard/agentCalculationHistory.route";
import PlayersRoute from 'routes/dashboard/players.route';
import PlayersEditRoute from 'routes/dashboard/playersEdit.route';
import RealTimeSportBetsRoute from './dashboard/realTimeSportBets.route';
import OnlineBetHistoryRoute from 'routes/dashboard/onlineBetHistory.route';
import RetailBetHistoryRoute from 'routes/dashboard/retailBetHistory.route';
import TerminalBetHistoryRoute from 'routes/dashboard/terminalBetHistory.route';
import AgentsRoute from 'routes/dashboard/agents.route';
import AgentsEditRoute from 'routes/dashboard/agentsEdit.route';
import NetworkRoute from 'routes/dashboard/network.route';
import BetshopsRoute from 'routes/dashboard/betshops.route';
import BetshopsEditRoute from 'routes/dashboard/betshopsEdit.route';
import CashiersRoute from 'routes/dashboard/cashiers.route';
import CashiersEditRoute from 'routes/dashboard/cashiersEdit.route';
import BetshopManagersRoute from 'routes/dashboard/betshopManagers.route';
import BetshopManagersEditRoute from 'routes/dashboard/betshopManagersEdit.route';
import TerminalsRoute from 'routes/dashboard/terminals.route';
import TerminalsEditRoute from 'routes/dashboard/terminalsEdit.route';
import UsersRoute from 'routes/dashboard/users.route';
import UsersEditRoute from 'routes/dashboard/usersEdit.route';
import AccessManagersRoute from 'routes/dashboard/accessManagers.route';
import AccessManagersEditRoute from 'routes/dashboard/accessManagersEdit.route';
import PermissionsRoute from 'routes/dashboard/permissions.route';
import PermissionGroupsRoute from 'routes/dashboard/permissionGroups.route';
import PermissionGroupsEditRoute from 'routes/dashboard/permissionGroupsEdit.route';
import PermissionsRequestsRoute from 'routes/dashboard/permissionRequests.route';
import RetailTranslationsRoute from "routes/dashboard/retailTranslations.route";
import BOTranslationsRoute from "routes/dashboard/BOTranslations.route";
import WidgetTranslationsRoute from "routes/dashboard/widgetTranslations.route";
import AgentTranslationsRoute from "routes/dashboard/agentTranslations.route";
import TerminalTranslationsRoute from "routes/dashboard/terminalTranslations.route";
import PayoutTicketRoute from "routes/dashboard/payoutTicket.route";
import FailedTransactionsRoute from "routes/dashboard/failedTransactions.route";
import UserLogsRoute from "routes/dashboard/userLogs.route";
import SystemCurrenciesRoute from "routes/dashboard/systemCurrencies.route";
import SystemLanguagesRoute from "routes/dashboard/systemLanguages.route";
import ErrorsRoute from "routes/dashboard/errors.route";
import RequestsRoute from "routes/dashboard/requests.route";
import JobsRoute from "routes/dashboard/jobs.route";
import GenerationsRoute from "routes/dashboard/generations.route";
import DbConnectionsRoute from 'routes/dashboard/dbConnections.route';
import MonitoringRoute from 'routes/dashboard/monitoring.route';
import PlatformTestRoute from 'routes/dashboard/platformTest.route';
import CalculateReportsRoute from 'routes/dashboard/calculateReports.route';
import PostDeploymentActionsRoute from 'routes/dashboard/postDeploymentActions.route';
import PaymentRequestsRoute from 'routes/dashboard/paymentRequests.route';
import PaymentHistoryRoute from 'routes/dashboard/paymentHistory.route';
import PlayerPerformanceReportRoute from 'routes/dashboard/playerPerformanceReport.route';
import BetshopPerformanceReportRoute from 'routes/dashboard/betshopPerformanceReport.route';
import CashierPerformanceReportRoute from 'routes/dashboard/cashierPerformanceReport.route';
import ProductReportRoute from 'routes/dashboard/productReport.route';
import PerformanceReportRoute from "routes/dashboard/performanceReport.route";
import NewProductReportRoute from "routes/dashboard/newProductReport";
import RedirectRoute from 'routes/redirect/redirect.route';
import TerminalCustomizeRoute from "routes/dashboard/terminalCustomize.route";
import VoucherRoute from "routes/dashboard/voucher.route";
//#endregion

import {ELEMENT_ROUTES} from "NEW/INTEGRATION/routes";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={Paths.DEFAULT} element={<RootLayout />} >
            {ELEMENT_ROUTES}

            <Route path="" element={<DashboardLayoutRoute />} >
                <Route path="" element={<MainLayoutRoute />} >
                    <Route path="" element={<DashboardRoute />} />
                    <Route path={Paths.PROFILE} element={<ProfileRoute />} />
                    <Route path={Paths.DASHBOARD} element={<DashboardChartsRoute />} />
                    <Route path={Paths.COMPANIES} element={<CompaniesRoute />} />
                    <Route path={`${Paths.COMPANIES_EDIT}/:id`} element={<CompaniesEditRoute />} />
                    <Route path={Paths.PROJECTS} element={<ProjectsRoute />} />
                    <Route path={`${Paths.PROJECTS_EDIT}/:id`} element={<ProjectsEditRoute />} />
                    <Route path={Paths.AGENTS} element={<AgentsRoute />} />
                    <Route path={`${Paths.AGENTS_EDIT}/:id`} element={<AgentsEditRoute />} />
                    <Route path={Paths.BETSHOP_OWNERS} element={<AgentsRoute />} />
                    <Route path={`${Paths.BETSHOP_OWNERS_EDIT}/:id`} element={<AgentsEditRoute />} />
                    <Route path={Paths.NETWORK} element={<NetworkRoute />} />
                    <Route path={Paths.REPORTS_AGENT_TRANSACTIONS} element={<AgentTransactionsReportRoute />} />
                    <Route path={Paths.REPORTS_BETSHOP_OWNER_TRANSACTIONS} element={<AgentTransactionsReportRoute />} />
                    <Route path={Paths.REPORTS_CASHIER_TRANSACTIONS} element={<CashierTransactionsReportRoute />} />
                    <Route path={Paths.REPORTS_BETSHOP_TRANSACTIONS} element={<BetshopTransactionsReportRoute />} />
                    <Route path={Paths.REPORTS_COMMISSION_CALCULATIONS} element={<CommissionCalculationsReportRoute />} />
                    <Route path={`${Paths.REPORTS_AGENT_CALCULATION_HISTORY}/:id`} element={<AgentCalculationHistoryRoute />} />
                    <Route path={`${Paths.REPORTS_AGENT_PERFORMANCE_REPORT_EDIT}/:id`} element={<AgentsEditRoute />} />
                    <Route path={Paths.REPORTS_PLAYER_PERFORMANCE_REPORT} element={<PlayerPerformanceReportRoute />} />
                    <Route path={Paths.REPORTS_BETSHOP_PERFORMANCE_REPORT} element={<BetshopPerformanceReportRoute />} />
                    <Route path={`${Paths.REPORTS_CASHIER_PERFORMANCE_REPORT}/:id`} element={<CashierPerformanceReportRoute />} />
                    <Route path={Paths.REPORTS_PRODUCT_REPORT} element={<ProductReportRoute />} />
                    <Route path={Paths.NEW_REPORTS_PRODUCT_REPORT} element={<NewProductReportRoute />} />
                    <Route path={Paths.REPORTS_PERFORMANCE_REPORTS} element={<PerformanceReportRoute />} />
                    <Route path={Paths.PLAYERS} element={<PlayersRoute />} />
                    <Route path={`${Paths.PLAYERS_EDIT}/:id`} element={<PlayersEditRoute />} />
                    <Route path={Paths.REAL_TIME_SPORT_BETS} element={<RealTimeSportBetsRoute />} />
                    <Route path={Paths.ONLINE_BETHISTORY} element={<OnlineBetHistoryRoute />} />
                    <Route path={Paths.RETAIL_BETHISTORY} element={<RetailBetHistoryRoute />} />
                    <Route path={Paths.TERMINAL_BETHISTORY} element={<TerminalBetHistoryRoute />} />
                    <Route path={Paths.BETSHOPS} element={<BetshopsRoute />} />
                    <Route path={`${Paths.BETSHOPS_EDIT}/:id`} element={<BetshopsEditRoute />} />
                    <Route path={Paths.CASHIERS} element={<CashiersRoute />} />
                    <Route path={`${Paths.CASHIERS_EDIT}/:id`} element={<CashiersEditRoute />} />
                    <Route path={Paths.BETSHOP_MANAGERS} element={<BetshopManagersRoute />} />
                    <Route path={`${Paths.BETSHOP_MANAGERS_EDIT}/:id`} element={<BetshopManagersEditRoute />} />
                    <Route path={Paths.TERMINALS} element={<TerminalsRoute />} />
                    <Route path={`${Paths.TERMINALS_EDIT}/:id`} element={<TerminalsEditRoute />} />
                    <Route path={Paths.USERS} element={<UsersRoute />} />
                    <Route path={`${Paths.USERS_EDIT}/:id`} element={<UsersEditRoute />} />
                    <Route path={Paths.ACCESS_MANAGERS} element={<AccessManagersRoute />} />
                    <Route path={`${Paths.ACCESS_MANAGERS_EDIT}/:id`} element={<AccessManagersEditRoute />} />
                    <Route path={Paths.PERMISSION_GROUPS} element={<PermissionGroupsRoute />} />
                    <Route path={`${Paths.PERMISSION_GROUPS_EDIT}/:id`} element={<PermissionGroupsEditRoute />} />
                    <Route path={Paths.PERMISSIONS} element={<PermissionsRoute />} />
                    <Route path={Paths.PERMISSION_REQUESTS} element={<PermissionsRequestsRoute />} />
                    <Route path={Paths.TRANSLATIONS_RETAIL} element={<RetailTranslationsRoute />} />
                    <Route path={Paths.TRANSLATIONS_BO} element={<BOTranslationsRoute />} />
                    <Route path={Paths.TRANSLATIONS_WIDGET} element={<WidgetTranslationsRoute />} />
                    <Route path={Paths.TRANSLATIONS_AGENT} element={<AgentTranslationsRoute />} />
                    <Route path={Paths.TRANSLATIONS_TERMINAL} element={<TerminalTranslationsRoute />} />
                    <Route path={Paths.PAYOUT_TICKET} element={<PayoutTicketRoute />} />
                    <Route path={Paths.VOUCHER} element={<VoucherRoute />} />
                    <Route path={Paths.FAILED_TRANSACTIONS} element={<FailedTransactionsRoute />} />
                    <Route path={Paths.USER_LOGS} element={<UserLogsRoute />} />
                    <Route path={Paths.SETTINGS_CURRENCIES} element={<SystemCurrenciesRoute />} />
                    <Route path={Paths.SETTINGS_LANGUAGES} element={<SystemLanguagesRoute />} />
                    <Route path={Paths.DEVELOPER_ERRORS} element={<ErrorsRoute />} />
                    <Route path={Paths.DEVELOPER_REQUESTS} element={<RequestsRoute />} />
                    <Route path={Paths.DEVELOPER_JOBS} element={<JobsRoute />} />
                    <Route path={Paths.DEVELOPER_MONITORING} element={<MonitoringRoute />} />
                    <Route path={Paths.DEVELOPER_PLATFORM_TEST} element={<PlatformTestRoute />} />
                    <Route path={Paths.DEVELOPER_GENERATIONS} element={<GenerationsRoute />} />
                    <Route path={Paths.DEVELOPER_DB_CONNECTIONS} element={<DbConnectionsRoute />} />
                    <Route path={Paths.DEVELOPER_CALCULATE_REPORTS} element={<CalculateReportsRoute />} />
                    <Route path={Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS} element={<PostDeploymentActionsRoute />} />
                    <Route path={Paths.PAYMENTS_REQUESTS} element={<PaymentRequestsRoute />} />
                    <Route path={Paths.PAYMENT_HISTORY} element={<PaymentHistoryRoute />} />
                    <Route path={Paths.REDIRECT} element={<RedirectRoute />} />
                </Route>
                <Route path={Paths.CUSTOMIZE} element={<CustomizeLayoutRoute />} >
                    <Route path="" element={<DashboardRoute />} />
                    <Route path={`${Paths.TERMINAL_CUSTOMIZE}/:id`} element={<TerminalCustomizeRoute />} />
                </Route>
            </Route>
        </Route>
    )
)

export default router;
