import {PERMISSION_RESOURCE} from "constants/permissions.constants";

export const mapping = {
    [PERMISSION_RESOURCE.DASHBOARD]: {
        icon: "icon-dashboard",
        order: 1
    },
    [PERMISSION_RESOURCE.COMPANY]: {
        icon: "icon-companies",
        order: 2
    },
    [PERMISSION_RESOURCE.PROJECT]: {
        icon: "icon-globe",
        order: 3
    },
    [PERMISSION_RESOURCE.AGENT]: {
        icon: "icon-agent",
        order: 4
    },
    [PERMISSION_RESOURCE.PAYMENT]: {
        icon: "icon-payments",
        order: 5
    },
    [PERMISSION_RESOURCE.REPORTS]: {
        icon: "icon-report",
        order: 6
    },
    [PERMISSION_RESOURCE.PLAYER]: {
        icon: "icon-player",
        order: 7
    },
    [PERMISSION_RESOURCE.BETS]: {
        icon: "icon-betslip",
        order: 8
    },
    [PERMISSION_RESOURCE.BETSHOP]: {
        icon: "icon-betshop",
        order: 9
    },
    [PERMISSION_RESOURCE.CASHIER]: {
        icon: "icon-cashier",
        order: 10
    },
    [PERMISSION_RESOURCE.BETSHOP_MANAGER]: {
        icon: "icon-cashier",
        order: 11
    },
    [PERMISSION_RESOURCE.TERMINAL]: {
        icon: "icon-cashier",
        order: 12
    },
    [PERMISSION_RESOURCE.ADMIN]: {
        icon: "icon-users",
        order: 13
    },
    [PERMISSION_RESOURCE.ACCESS_MANAGER]: {
        icon: "icon-users",
        order: 14
    },
    [PERMISSION_RESOURCE.PERMISSION]: {
        icon: "icon-permission",
        order: 15
    },
    [PERMISSION_RESOURCE.FAILED_TRANSACTIONS]: {
        icon: "icon-failed-request",
        order: 16
    },
    [PERMISSION_RESOURCE.USER_LOGS]: {
        icon: "icon-userLogs",
        order: 17
    },
    [PERMISSION_RESOURCE.TRANSLATION]: {
        icon: "icon-translations",
        order: 18
    },
    [PERMISSION_RESOURCE.SYSTEM_CURRENCIES]: {
        icon: "icon-currency",
        order: 19
    },
    [PERMISSION_RESOURCE.SYSTEM_LANGUAGES]: {
        icon: "icon-languages",
        order: 20
    },
    [PERMISSION_RESOURCE.DEVELOPER]: {
        icon: "icon-code",
        order: 21
    },
}
