import dateService from "utils/date";

import { FILTER_TYPE, ORDER_DIRECTION } from "constants/common.constants";

import sessionStorageUtils from 'utils/sessionStorage';
import LocalStorageUtils from "utils/localStorage";
import { isString } from "utils/javaScriptTypes";
import { flagsToBinary } from "utils/common";

import { COMPANY_PASSWORD_PATTERN } from "constants/company.constants";
import { INTEGRATION_TYPE, PROJECT_TYPE, REGISTRATION_FORM_TYPE, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";
import { TICKET_GENERAL_DETAILS, TICKET_BET_DETAILS } from "constants/ticket.constants";
import { COMMISSION_SETTINGS_TYPE } from "constants/commission.constants";
import { TRANSLATION_TYPE } from "constants/translation.constants";
import { PLATFORM_BET_STATE } from "constants/bet.constants";

import {
	FILTER_STATUS,
	FILTER_TEST_STATE,
	FILTER_USER_STATE,
	FILTER_USER_ONLINE_STATE,
	FILTER_BET_STATE,
	FILTER_PROVIDER,
	FILTER_PLAYER_STATE,
	FILTER_COUNTRY,
	FILTER_REAL_TIME_SPORT_BET_DATA_TYPE, BETSLIP_FILTER_TYPE
} from "constants/filter.constants";

import { AGENT_REPORT_TYPES } from "constants/reports.constants";
import { NOTIFICATION_SOUND_TYPES } from "../constants/notification.constants";
import {FILTER_USER_TYPE} from "constants/user.constants";

import { CUSTOMIZE_FONT_FAMILIES } from "constants/customize.constants";
import { TERMINAL_SECTION_TYPE } from "constants/terminalCustomize.constants";

export default {
	common: {
		globalCompanyId: sessionStorageUtils.get("selectedCompanyId") || null,
		globalProjectId: sessionStorageUtils.get("selectedProjectId") || null,
		globalProjectType: sessionStorageUtils.get("selectedProjectType") || null,
		globalProjectMode: sessionStorageUtils.get("selectedProjectMode") || null,
		globalPaymentType: sessionStorageUtils.get("selectedPaymentType") || null,
		platVersion: sessionStorageUtils.get("platVersion") || 'v1.0',
		availableLanguages: {
			isLoading: false,
			availableLanguages: {}
		},
		gameProviders: []
	},
	auth: {
		isLoading: false,
		expires: -1,
		lastLoginedUserName: null,
		QRBase64: null,
		passwordSettings: {
			isLoading: false,
			passwordSettings: {},
		},
		resetPassword: {
			isReseting: false,
		},
		wsToken: sessionStorageUtils.get("authorizationData") ? sessionStorageUtils.get('authorizationData').wsToken : null,
		translations: {
			languages: sessionStorageUtils.get("languages") ?? {},
			isLanguagesLoading: false,
			isLanguagesLoaded: sessionStorageUtils.get("languages") ? true : false,
			loaded: false,
		}
	},
	companies: {
		companies: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: ""
		},
		edit: {
			general: {},
			languages: {
				languages: []
			},
			passwordSettings: {
				passwordMinLimit: 12,
				passwordMaxLimit: 24,
				forceChange: 3,
				pattern: {
					type: COMPANY_PASSWORD_PATTERN.CONTAINS,
					contain: [],
					regularExpression: "",
				}
			},
			tokenSettings: {
				lifetimeMinutes: 15,
				multideviceLogin: true,
			},
			authenticationSettings: {}
		}
	},
	projects: {
		projects: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: "",
			isEnabled: FILTER_STATUS.ALL,
			isTesting: FILTER_TEST_STATE.ALL,
			to: "",
			from: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableCurrencies: {
			availableCurrencies: {},
			isLoading: false
		},
		edit: {
			general: {
				type: PROJECT_TYPE.AGENT_SYSTEM
			},
			currencies: [],
			provider: 0,
			agentSystem: {
				network: {
					network: []
				},
				administrativeFee :{}
			},
			registrationForm: {
				agentRegistrationForm: [],
				playerRegistrationForm: []
			},
			limitSettings: {},
            favoriteAmounts: {
                agentFavoriteAmounts: [],
                playerFavoriteAmounts: [],
                betShopFavoriteAmounts: []
            },
			configs: {
				main: {},
				smsConfigs: {
					smsProviders: [],
					projectProvider: null,
				}
			},
			retailProviderSettings: {},
            terminalProviderSettings: {},
			integration: {},
			chatUI: {},
			payment: {
				config: {},
				banks: {
					banks: [],
					edit: {
						info: {
							name: null,
							currencies: [],
							state: null,
						},
						bankForm: {
							[REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT]: {
								forms: [],
								translations: []
							},
							[REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL]: {
								forms: [],
								translations: []
							},
						},
						widget: {
							[WIDGET_TRANSACTION_TYPE.DEPOSIT]: null,
							[WIDGET_TRANSACTION_TYPE.WITHDRAWAL]: null,
						}
					}
				}
			},
            subProjects: []
		},
		retailProviders: []
	},
	agents: {
		agents: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		totals: {},
		isTotalsLoading: false,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: "",
			state: FILTER_USER_STATE.ALL,
			email: "",
			from: "",
			to: "",
			lastLoginFrom: "",
			lastLoginTo: "",
			parentUserNameOrId: "",
			countries: FILTER_COUNTRY.ALL,
			currencies: [],
			suspended: null,
			sendDeleted: false
		},
		subAgents: {
			isLoading: false,
			agents: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				nameOrId: "",
				state: FILTER_USER_STATE.ALL,
				email: "",
				countries: FILTER_COUNTRY.ALL,
				from: "",
				to: "",
				lastLoginFrom: "",
				lastLoginTo: "",
			}
		},
		players: {
			isLoading: false,
			players: [],
			totals: {},
			isTotalsLoading: false,
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				//nameOrId: "",
				email: "",
				from: "",
				to: ""
			},
		},
		betshops: {
			isLoading: false,
			betshops: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				nameOrId: "",
				to: "",
				from: ""
			},
		},
		isRegistrationFormLoading: false,
		registrationForm: [],
		isAvailableAgentsForReparentingLoading: false,
		availableAgentsForReparenting: [],
		edit: {
			general: {},
			additionalAccesses: {},
			currencies: {
				isLoading: false,
				currencies: [],
				availableCurrencies: {},
				isAvailableLoading: false
			},
			limitSettings: {},
			payment: {
				config: {},
				eposSettings: {
					workingSchedule: {},
					translations: [],
					contacts: []
				},
				banks: {
					banks: [],
					edit: {
						info: {
							name: null,
							currencies: [],
							state: null,
						},
						details: {
							[REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT]: {},
							[REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL]: {}
						},
						widget: {
							[WIDGET_TRANSACTION_TYPE.DEPOSIT]: null,
							[WIDGET_TRANSACTION_TYPE.WITHDRAWAL]: null,
						}
					},
					availableBanks: []
				}
			}
		}
	},
	agentTransactionsReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "TransactionTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			groupId: "",
			transactionTypes: [],
			currencyCode: "",
			participant1Type: "",
			participant1: null,
			participant2Type: "",
			participant2: null,
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
			includeRelated: true
		}
	},
	cashierTransactionsReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "TransactionTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			groupId: "",
			betShopId: null,
			cashier: null,
			player: null,
			transactionTypes: [],
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		}
	},
	betshopTransactionsReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "TransactionTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			groupId: "",
			transactionTypes: [],
			cashier: null,
			betShopId: null,
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		}
	},
	commissionCalculationsReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CalculatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: "",
			onlineProvider: "",
			retailProviders: "",
			type: "",
			sourceDirection: "",
			currencyCode: "",
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
			includeZeroAmounts: false
		}
	},
	agentPerformanceReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "AgentRegistrationDate",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			currencies: [],
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
			registerredPeriodFrom: "",
			registerredPeriodTo: "",
			agentLevel: "",
			type: AGENT_REPORT_TYPES.DIRECT,
		},
		totals: {},
		isTotalsLoading: false
	},
	playerPerformanceReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "PlayerRegistrationDate",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			currencies: [],
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
			registerredPeriodFrom: "",
			registerredPeriodTo: "",
			belongsTo: null,
			filterType: FILTER_TYPE.NETWORK,
		},
		totals: {},
		isTotalsLoading: false
	},
	betshopPerformanceReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "GGR",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			betShopNameOrId: "",
			currencies: [],
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
			agentUserNameOrId: null,
			filterType: FILTER_TYPE.NETWORK,
		},
		totals: {},
		isTotalsLoading: false
	},
	cashierPerformanceReport: {
		report: [],
		isLoading: false
	},
    productReport: {
        report: {},
        isLoading: false,
        filters: {
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
			type: FILTER_TYPE.NETWORK,
			user: [
				FILTER_USER_TYPE.AGENT,
				"",
			]
		},
    },
	agentCalculationHistory: {
		history: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10
		},
		filters: {
			userNameOrId: ""
		}
	},
	wallet: {
		isLoading: false,
		isSaving: false,
		agentWallet: {
			wallets: []
		},
        agentCurrencies: [],
		history: {
			transfers: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "TransactionTime",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				transactionId: "",
				transactionType: "",
				currencyCode: "",
				userNameOrId: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			}
		}
	},
	commissionPlans: {
		commissionPlans: [],
		availableCommissionPlans: [],
		isAvailableCommissionPlansLoading: false,
		edit: {
			settings: {
				ranges: []
			},
			sportSettings: {
				[COMMISSION_SETTINGS_TYPE.SPORT_PRE_MATCH]: [],
				[COMMISSION_SETTINGS_TYPE.SPORT_LIVE]: []
			},
			agents: {
				agents: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "AddedAt",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				filters: {
					userNameOrId: ""
				}
			}
		}
	},
	network: {
		isLoading: false,
		tree: {},
		layers: []
	},
	players: {
		players: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			playerUsername: "",
			playerId: "",
			externalId: "",
			state: FILTER_PLAYER_STATE.ALL,
			email: "",
			parentUserNameOrId: "",
			currencies: [],
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		edit: {
			general: {},
			bets: {
				bets: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetDate",
					orderDirection: ORDER_DIRECTION.DESC
				},
				filters: {
					betId: "",
					providerId: "",
					day: dateService.getNow(true),
					time: [
						dateService.startOfDay(),
						dateService.endOfDay()
					]
				},
				totals: {},
			},
			playerTransactionsHistory: {
				history: [],
				hasNext: false,
                sorting: {
                    page: 1,
                    limit: 10,
                    orderBy: "Date",
                    orderDirection: ORDER_DIRECTION.DESC
                },
                filters: {
					day: dateService.getNow(true),
					time: [
						dateService.startOfDay(),
						dateService.endOfDay()
					],
                }
			}
		},
		isRegistrationFormLoading: false,
		registrationForm: [],
		info: null,
		withdrawal: null
	},
	betHistory: {
		isLoading: false,
		realTimeBets: {
			sportBets: {
				bets: [],
				total: 0,
				totals: {},
				filters: {
					playerExternalId: "",
					currencyCode: "",
					betSlipType: "",
					bettingType: "",
					betState: PLATFORM_BET_STATE.PENDING,
					day: dateService.getNow(true),
					time: [
						dateService.startOfDay(),
						dateService.endOfDay()
					],
					filterType: FILTER_REAL_TIME_SPORT_BET_DATA_TYPE.BET_DATE_TIME
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetDate",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				details: {}
			},
			casinoBets: {
				bets: [],
				total: 0,
				totals: {},
				filters: {
					currencyCode: "",
					providerId: "",
					playerExternalId: "",
					day: dateService.getNow(true),
					time: [
						dateService.startOfDay(),
						dateService.endOfDay()
					]
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetDate",
					orderDirection: ORDER_DIRECTION.DESC,
				}
			}
		},
		online: {
			aggregated: {
				bets: [],
				total: 0,
				filters: {
					playerUserNameOrId: "",
					belongsTo: null,
					provider: "",
					currencyCode: "",
                    from: dateService.toISOString(dateService.yesterday(true)),
                    to: dateService.toISOString(dateService.startOfTomorrow()),
                    filterType: FILTER_TYPE.NETWORK
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "From",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				totals: {
					data: {},
					loading: false
				},
				loading: false
			},
			sport: {
				bets: [],
				total: 0,
				filters: {
					betSlipId: "",
					playerUserNameOrId: "",
					betSlipFilterType: BETSLIP_FILTER_TYPE.BET_TIME,
					belongsTo: null,
					currencyCode: "",
					type: "",
					bettingType: "",
					status: FILTER_BET_STATE.ALL,
					from: dateService.toISOString(dateService.yesterday(true)),
					to: dateService.toISOString(dateService.startOfTomorrow()),
					filterType: FILTER_TYPE.NETWORK
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				totals: {},
				loading: false
			}
		},
		retail: {
			pendings: {
				bets: [],
				total: 0,
				filters: {
					betSlipId: "",
					cashierUserNameOrId: "",
					betShopNameOrId: "",
					belongsTo: null,
					playerUserNameOrId: "",
					type: "",
					providertype: FILTER_PROVIDER.ALL,
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC,
				},
			},
			settled: {
				bets: [],
				total: 0,
				totals: {},
				filters: {
					betSlipId: "",
					betSlipFilterType: BETSLIP_FILTER_TYPE.BET_TIME,
					cashierUserNameOrId: "",
					playerUserNameOrId: "",
					betShopNameOrId: "",
					belongsTo: null,
					providertype: FILTER_PROVIDER.ALL,
					status: FILTER_BET_STATE.ALL,
					type: "",
					from: dateService.toISOString(dateService.yesterday(true)),
					to: dateService.toISOString(dateService.startOfTomorrow()),
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC,
				},
			}
		},
        terminal: {
			pendings: {
				bets: [],
				total: 0,
				filters: {
					betSlipId: "",
					terminalNameOrId: "",
					betShopNameOrId: "",
					belongsTo: null,
					type: "",
					providertype: FILTER_PROVIDER.ALL,
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC,
				},
			},
			settled: {
				bets: [],
				total: 0,
				totals: {},
				filters: {
					betSlipId: "",
					betSlipFilterType: BETSLIP_FILTER_TYPE.BET_TIME,
					terminalUserNameOrId: "",
					betShopNameOrId: "",
					belongsTo: null,
					providertype: FILTER_PROVIDER.ALL,
					status: FILTER_BET_STATE.ALL,
					type: "",
					from: dateService.toISOString(dateService.yesterday(true)),
					to: dateService.toISOString(dateService.startOfTomorrow()),
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC,
				},
			}
		}
	},
	betshops: {
		betshops: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: "",
			parentUserNameOrId: "",
			code: "",
			groupId: "",
			state: FILTER_USER_STATE.ALL,
			isTesting: FILTER_TEST_STATE.ALL,
			to: "",
			from: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableCurrencies: {
			isLoading: false,
			availableCurrencies: {}
		},
		edit: {
			general: {},
			limitSettings: {
				enabled: false,
				fixedAmount: 0,
				frequencySettings: {
					every: 1,
					at: {
						hour: 0,
						minute: 0,
					},
					timeZoneId: "",
					dst: 0,
				},
				threshold: null,
				allowNotification: false,
			},
			additionalAccess: {},
			ipAccessRule: {
				enabled: false,
				allowedIps: []
			},
			currency: {}
		}
	},
	cashiers: {
		cashiers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			onlineState: FILTER_USER_ONLINE_STATE.ALL,
			betShopId: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableBetshops: {
			isLoading: false,
			availableBetshops: {}
		},
		edit: {
			general: {},
			additionalAccess: {}
		}
	},
	betshopManagers: {
		betshopManagers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			onlineState: FILTER_USER_ONLINE_STATE.ALL,
			betShopId: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableBetshops: {
			isLoading: false,
			availableBetshops: {}
		},
		edit: {
			general: {}
		}
	},
    terminals: {
		terminals: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: "",
			betShopId: "",
			status: "",
			to: "",
			from: "",
			modifiedFrom: "",
			modifiedTo: "",
		}
	},
	users: {
		users: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			permissionGroup: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
			sendDeleted: false
		},
		availableCompanies: {
			isLoading: false,
			availableCompanies: {}
		},
		edit: {
			general: {},
			permissionGroups: {
				groups: [],
				availableGroups: [],
				isAvailableGroupsLoading: false,
			},
			permissions: [],
			companyAccess: {
				companies: [],
				mainCompany: {},
				availableCompanies: [],
			},
			additionalAccesses: {}
		}
	},
	accessManagers: {
		accessManagers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		edit: {
			general: {}
		}
	},
	account: {
		isLoading: false,
		isSaving: false,
		projectAccess: {
			companies: [],
		}
	},
	permissions: {
		permissions: [],
		isLoading: false,
		isResourcesLoading: false,
		resources: [],
	},
	permissionGroups: {
		isLoading: false,
		isSaving: false,
		permissionGroups: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		edit: {
			general: {
				name: "",
				permissions: [],
			},
			users: {
				users: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "AddedAt",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				filters: {
					userNameOrId: "",
				}
			}
		},
	},
	permissionRequests: {
		isLoading: false,
		isSaving: false,
		pending: {
			requests: [],
		},
		history: {
			history: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "ModifiedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				objectType: "",
				actionTypes: null,
				status: "",
				modifiedFrom: dateService.toISOString(dateService.yesterday(true)),
				modifiedTo: dateService.toISOString(dateService.startOfTomorrow()),
				from: "",
				to: ""
			}
		},
		count: {}
	},
	profile: {
		userInfo: sessionStorageUtils.get("userInfo")
			? {
				...sessionStorageUtils.get("userInfo"),
				notificationSetting: sessionStorageUtils.get("userInfo").notificationSetting ?? { notificationSound: NOTIFICATION_SOUND_TYPES.DEFAULT }
			}
			: {
				companies: [],
				notificationSetting: { notificationSound: NOTIFICATION_SOUND_TYPES.DEFAULT }
			},
		isUserInfoLoading: !Boolean(sessionStorageUtils.get("userInfo")),
		currency: isString(sessionStorageUtils.get("currency")) ? sessionStorageUtils.get('currency') : null,
        currencies: [],
		personalInformation: {
			companies: []
		},
		formatSettings: {},
		isLoading: false,
		isSaving: false,
		isPasswordChanging: false,
		isProjectChanging: false
	},
	sessions: {
		sessions: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "StartTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		},
	},
	translations: {
		isLoading: false,
		isGroupsLoading: false,
		isPublishing: false,
		isUnpublishing: false,
		groups: {
			[TRANSLATION_TYPE.RETAIL]: [],
			[TRANSLATION_TYPE.BO]: [],
			[TRANSLATION_TYPE.WIDGET]: [],
            [TRANSLATION_TYPE.AGENT]: [],
            [TRANSLATION_TYPE.TERMINAL]: []
		},
		translations: [],
		systemDefault: LocalStorageUtils.get("systemDefaultTranslation") === null ? true : LocalStorageUtils.get("systemDefaultTranslation"),
	},
	payoutTicket: {
		isLoading: false,
		isSaving: false,
		ticketLogoId: null,
		payoutTicket: {},
		currentPayoutTicket: {
			generalDetails: flagsToBinary(Object.values(TICKET_GENERAL_DETAILS)),
			betDetails: flagsToBinary(Object.values(TICKET_BET_DETAILS)),
			customText: "",
			enableBarcodePrinting: false
		}
	},
	voucher: {
		isLoading: false,
		isSaving: false,
		ticketLogoId: null,
		voucher: {},
		currentVoucher: {
			generalDetails: flagsToBinary(Object.values(TICKET_GENERAL_DETAILS)),
			betDetails: flagsToBinary(Object.values(TICKET_BET_DETAILS)),
			customText: "",
			enableBarcodePrinting: false
		}
	},
	systemCurrencies: {
		currencies: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		availableCurrencies: {},
		systemAvailableCurrencies: [],
		isAvailableLoading: false
	},
	systemLanguages: {
		languages: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		availableLanguages: {},
		systemAvailableLanguages: {},
		isAvailableLoading: false
	},
	notifications: {
		isLoading: false,
		canLoadMore: false,
		notifications: [],
		unreadNotificationsCount: sessionStorageUtils.get("unreadNotificationsCount") || 0,
		sound: false,
	},
	failedTransactions: {
		isLoading: false,
		failedTransactions: [],
		total: 0,
		filters: {
			id: "",
			actionType: "",
			paymentType: "",
			groupId: "",
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
	},
	userLogs: {
		isLoading: false,
		userLogs: [],
		total: 0,
		filters: {
			userNameOrId: "",
			resource: "",
			resourceId: "",
			action: "",
			actionType: "",
			year: dateService.getCurrentYMD().year,
			month: dateService.getCurrentYMD().month,
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "LogTime",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		resources: {},
	},
	errors: {
		isLoading: false,
		errors: [],
		total: 0,
		filters: {
			apiType: "",
			userId: "",
			controller: "",
			action: "",
			year: dateService.getCurrentYMD().year,
			month: dateService.getCurrentYMD().month,
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "ErrorTime",
			orderDirection: ORDER_DIRECTION.DESC,
		}
	},
	requests: {
		isLoading: false,
		[INTEGRATION_TYPE.PLATFORM]: {
			requests: [],
			total: 0,
			filters: {
                projectId: "",
				status: "",
				action: "",
				year: dateService.getCurrentYMD().year,
				month: dateService.getCurrentYMD().month,
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			}
		},
		[INTEGRATION_TYPE.RETAIL]: {
			requests: [],
			total: 0,
			filters: {
                projectId: "",
				status: "",
				action: "",
				year: dateService.getCurrentYMD().year,
				month: dateService.getCurrentYMD().month,
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			}
		}
	},
	generations: {
		generations: [],
		isLoading: false
	},
	dbConnections: {
		dbConnections: {},
		isLoading: false
	},
	jobs: {
		jobs: [],
		isLoading: false,
		isSaving: false,
		isDetailsLoading: false,
		jobSettings: {},
		jobDetails: {},
	},
	monitoring: {
		isLoading: false,
		isServicesLoading: false,
		services: [],
		service: {},
	},
	calculateReports: {
		isLoading: false
	},
	postDeploymentActions: {
		isLoading: false,
		isDataExporting: false,
		languagesData: {
			isLoading: false,
			languages: [],
		}
	},
	platforms: {
		isLoading: false,
		platforms: [],
		isTesting: false,
		platformTests: {
			gateways: []
		}
	},
	system: {
		isTimezonesLoading: false,
		timezones: []
	},
	paymentRequests: {
		isLoading: false,
		[WIDGET_TRANSACTION_TYPE.DEPOSIT]: {
			paymentRequests: [],
			sorting: {
				orderBy: "createdAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				playerExternalId: "",
				userNameOrId: "",
				bankId: "",
				from: dateService.toISOString(dateService.monthsAgo(3, true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
		},
		[WIDGET_TRANSACTION_TYPE.WITHDRAWAL]: {
			paymentRequests: [],
			sorting: {
				orderBy: "createdAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				playerExternalId: "",
				userNameOrId: "",
				bankId: "",
				paymentType: "",
				from: dateService.toISOString(dateService.monthsAgo(3, true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
		},

		translations: {},
		isTranslationsLoading: false
	},
	paymentHistory: {
		isLoading: false,
		[WIDGET_TRANSACTION_TYPE.DEPOSIT] : {
			paymentHistory: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				userNameOrId: "",
				transactionId: "",
				walletTransactionId: "",
				paymentType: "",
				playerExternalId: "",
				state: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
				createdAtFrom: "",
				createdAtTo: ""
			},
		},
		[WIDGET_TRANSACTION_TYPE.WITHDRAWAL] : {
			paymentHistory: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				userNameOrId: "",
				transactionId: "",
				walletTransactionId: "",
				paymentType: "",
				playerExternalId: "",
				state: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
				createdAtFrom: "",
				createdAtTo: ""
			},
		}
	},
    terminalCustomize: {
		customizeLanguage: "EN",
		isLoading: false,
		isSaving: false,
		isPublishing: false,
		configuration: {},
		colors: {
			brandColor: "",
			backgroundColor: "",
			textAndIconColor: "",
		},
		fontFamily: CUSTOMIZE_FONT_FAMILIES[0],
		images: {},
		languages: [],
		sections: {
			[TERMINAL_SECTION_TYPE.HEADER]: {
				data: []
			},
            [TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS]: {
				data: []
			},
            [TERMINAL_SECTION_TYPE.PROMOTIONS]: {
				data: []
			},
            [TERMINAL_SECTION_TYPE.TERMS_AND_CONDITIONS]: {
				data: {}
			},
            [TERMINAL_SECTION_TYPE.FOOTER_GENERAL]: {
				data: []
			},
            [TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES]: {
				data: []
			},
            [TERMINAL_SECTION_TYPE.SECOND_SCREEN]: {
				data: []
			},
            [TERMINAL_SECTION_TYPE.SECOND_SCREEN_CONFIG]: {
				data: {}
			},
		},
	},
}
