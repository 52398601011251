import React, {useMemo, useState} from 'react';
import {Button, message} from "antd";
import {TERMINAL_STATE} from "constants/terminal.constants";
import {useTranslation} from "react-i18next";
import {changeTerminalState} from "pages/retail/terminals/edit/sections/general/api";
import getModalDefaultState
    from "pages/retail/terminals/edit/sections/general/components/terminalActions/helpers/getModalDefaultState";
import Modal from "components/common/modal";
import {isMobile} from "utils/common";

const TerminalActions = ({ terminal, onStatusChange }) => {
    const [modal, setModal] = useState(getModalDefaultState());

    const { t } = useTranslation();

    const canDeactivate = terminal.status === TERMINAL_STATE.ACTIVE || terminal.status === TERMINAL_STATE.BLOCKED;
    const canBlock = terminal.status === TERMINAL_STATE.ACTIVE;
    const canUnblock = terminal.status === TERMINAL_STATE.BLOCKED;

    const terminalRequestInfo = useMemo(() => ({
        id: terminal.id
    }), [terminal])

    const handleDeactivate = () => {
        setModal({
            opened: true,
            onOk: () => changeState(TERMINAL_STATE.INACTIVE),
            title: t("backoffice.terminals.deactivate"),
            okText: t("backoffice.terminals.deactivate"),
            message: t("backoffice.terminals.deactivateMessage")
        });
    }

    //Are you sure you want to deactivate the terminal?

    const handleBlock = () => {
        setModal({
            opened: true,
            onOk: () => changeState(TERMINAL_STATE.BLOCKED),
            title: t("backoffice.terminals.block"),
            okText: t("backoffice.terminals.block"),
            message: t("backoffice.terminals.blockMessage")
        });
    }

    const handleUnblock = () => {
        setModal({
            opened: true,
            onOk: () => changeState(TERMINAL_STATE.ACTIVE),
            title: t("backoffice.terminals.unblock"),
            okText: t("backoffice.terminals.unblock"),
            message: t("backoffice.terminals.unblockMessage")
        });
    }

    const changeState = (state) => {
        setModal(state => ({ ...state, loading: true }));

        changeTerminalState({
            ...terminalRequestInfo,
            status: state
        })
            .then(({ message: successMessage }) => {
                message.success(successMessage);
                handleCloseModal();

                onStatusChange(state)
            })
            .finally(() => {
                setModal(state => ({ ...state, loading: false }));
            })
    }

    const handleCloseModal = () => {
        setModal(getModalDefaultState());
    }

    return (
        <>
            <div className="rt--flex">
                {canDeactivate && (
                    <Button
                        className="rt--button rt--button-secondary rt--mr-12"
                        onClick={handleDeactivate}
                        icon={isMobile() && <i className="icon-deactivate" />}
                    >
                        {!isMobile() && t("backoffice.terminals.deactivate")}
                    </Button>
                )}

                {canBlock && (
                    <Button
                        className="rt--button rt--button-secondary"
                        onClick={handleBlock}
                        icon={isMobile() && <i className="icon-block2" />}
                    >
                        {!isMobile() && t("backoffice.terminals.block")}
                    </Button>
                )}

                {canUnblock && (
                    <Button
                        className="rt--button rt--button-secondary"
                        onClick={handleUnblock}
                        icon={isMobile() && <i className="icon-unblock" />}
                    >
                        {!isMobile() && t("backoffice.terminals.unblock")}
                    </Button>
                )}
            </div>

            {modal.opened && (
                <Modal
                    title={modal.title}
                    okText={t("backoffice.common.yes")}
                    cancelText={t("backoffice.common.no")}
                    onOk={modal.onOk}
                    onCancel={handleCloseModal}
                    confirmLoading={modal.loading}
                >
                    {modal.message}
                </Modal>
            )}
        </>
    );
};

export default TerminalActions;
