import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { setTerminalSettledBetHistoryFilters, getTerminalSettledBetHistory } from "store/actions/dashboard/betHistory/terminalSettled.action";

import {FILTER_PROVIDER, FILTER_BET_STATE, BETSLIP_FILTER_TYPE} from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { DATE_PICKER_RANGES } from 'constants/common.constants';
import { BET_STATE, BET_TYPE } from 'constants/bet.constants';

import { makeBetStateText } from "utils/bet";

import useAutosuggestion from 'hooks/useAutosuggestion';
import { getPossibleParentsName } from 'utils/common';


/** Settled Bets Page Filters Component */

const Filters = ({
    setTerminalSettledBetHistoryFilters,
    getTerminalSettledBetHistory,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: false
    });
    const [ terminalNames, getTerminalNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.TERMINAL,
        autoGet: false
    });
    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: false
    });

    const belongsToFieldOptions = useMemo(() => {
        const options = getPossibleParentsName({
            globalProjectId,
            agentNames,
        })

        return [{value: null, text: t("backoffice.common.all")}].concat(options.map(o => ({
            value: o.id,
            text: o.name
        })))

    }, [globalProjectId, agentNames]);

    const dateTypeOptions = useMemo(() => {
        return [
            {
                text: t('backoffice.bets.betDate'),
                value: BETSLIP_FILTER_TYPE.BET_TIME
            },
            {
                text: t('backoffice.bets.calculationDate'),
                value: BETSLIP_FILTER_TYPE.CALCULATION_TIME
            },
            {
                text: t('backoffice.bets.payoutDate'),
                value: BETSLIP_FILTER_TYPE.PAYOUT_TIME
            },
        ]
    }, []);

    return (
        <FiltersWrapper
            loadFn={getTerminalSettledBetHistory}
            setFiltersFn={setTerminalSettledBetHistoryFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true }
            ]}
            searchFieldName="betSlipId"
            onInit={() => {
                getAgentNames({
                    excludeAgent: false
                });
                getBetshopNames();
                getTerminalNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.dateType')}
                        name="betSlipFilterType"
                    >
                        <Select options={dateTypeOptions}/>
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            disabledRanges={[DATE_PICKER_RANGES.YTD]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.belongsTo')}
                        name="belongsTo"
                    >
                        <Select
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.belongsTo')}`}
                            options={belongsToFieldOptions}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.betshopNameOrId')}
                        name="betShopNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.betshopNameOrId')}`}
                            items={betshopNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.terminalNameOrId')}
                        name="terminalUserNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.terminalNameOrId')}`}
                            items={terminalNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.provider')}
                        name="providertype"
                    >
                        <Select
                            options={[
                                { value: FILTER_PROVIDER.ALL, text: t("backoffice.common.all") },
                                { value: FILTER_PROVIDER.SPORTBOOK, text: t("backoffice.common.Sportsbook") },
                                { value: FILTER_PROVIDER.VIRTUAL_SPORTS, text: t("backoffice.common.DGVirtuals") },
                                { value: FILTER_PROVIDER.GOLDEN_RACE, text: t("backoffice.common.GoldenRace") }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.provider')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.status")}
                        name="status"
                    >
                        <Select
                            options={
                                Object.keys(FILTER_BET_STATE).map(k => (
                                    {
                                        value: FILTER_BET_STATE[k],
                                        text: FILTER_BET_STATE[k] !== "" ? makeBetStateText(FILTER_BET_STATE[k]) : t("backoffice.common.all")
                                    }
                                )).concat([
                                    {
                                        value: BET_STATE.PAID_OUT,
                                        text: t("backoffice.bets.paidOut")
                                    },
                                    {
                                        value: BET_STATE.CASHED_OUT,
                                        text: t("backoffice.bets.cashedOut")
                                    }
                                ])
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.type")}
                        name="type"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    { value: BET_TYPE.SINGLE, text: t("backoffice.bets.single") },
                                    { value: BET_TYPE.MULTI, text: t("backoffice.bets.multi") },
                                    { value: BET_TYPE.SYSTEM, text: t("backoffice.bets.system") }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.type')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get terminal settled bet histpry */
    getTerminalSettledBetHistory: PropTypes.func,
    /** Redux action to set terminal settled bet history filters */
    setTerminalSettledBetHistoryFilters: PropTypes.func,
    /** Redux state property, terminal settled bet history filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getTerminalSettledBetHistory: () => {
            dispatch(getTerminalSettledBetHistory())
        },
        setTerminalSettledBetHistoryFilters: filters => {
            dispatch(setTerminalSettledBetHistoryFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.betHistory.terminal.settled.filters,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
