import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Row, Col, Spin } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import VirtualSportsSettingsComponent from './virtualSports';
import SportbookSettingsComponent from './sportsbook';
import GoldenRaceSettingsComponent from './glodenRace';

import { 
    getProjectRetailProviderSettings, 
    saveProjectRetailProviderSettings,
    getProjectTerminalProviderSettings, 
    saveProjectTerminalProviderSettings 
} from "store/actions/dashboard/projects/providerSettings.action";

import { PROJECT_PROVIDER_TYPE, PROJECT_TYPE } from "constants/project.constants"

import providerSettingsType from "types/project/providerSettings.type";


/** Project Edit Page Provider Settings Tab Component */
const ProviderSettingsComponent = ({
    projectType,
    getProjectRetailProviderSettings,
    saveProjectRetailProviderSettings,
    getProjectTerminalProviderSettings,
    saveProjectTerminalProviderSettings,
    isSaving,
    isLoading,
    retailProviderSettings,
    terminalProviderSettings,
    onTabChange
}) => {

    const providerSettings = projectType === PROJECT_TYPE.RETAIL_SYSTEM ? retailProviderSettings : terminalProviderSettings;

    /** Fires when form submitted
       * @function
       * @param {number} type
       * @param { object } data
       * @memberOf ProviderSettingsComponent
   */
    const handleSave = (type, data) => {
        let d = {};
        d.type = type;
        if (type === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS) {
            d["virtualSportsSettings"] = data;
        } else if (type === PROJECT_PROVIDER_TYPE.SPORTBOOK) {
            d["sportsbookSettings"] = data;
        } else if (type === PROJECT_PROVIDER_TYPE.GOLDEN_RACE) {
            d["goldenRaceSettings"] = data;
        }

        if(projectType === PROJECT_TYPE.RETAIL_SYSTEM){
            saveProjectRetailProviderSettings(d)
        } else {
            saveProjectTerminalProviderSettings(d)
        }
    }

    useEffect(() => {
        if(projectType === PROJECT_TYPE.RETAIL_SYSTEM){
            getProjectRetailProviderSettings();
        } else {
            getProjectTerminalProviderSettings();
        }
        
    }, [])

    return (
        <SubTabFormDashboardLayout>
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={24} xl={8} >
                        <VirtualSportsSettingsComponent
                            settings={ providerSettings?.virtualSportsSettings ?? {}}
                            onSave={data => handleSave(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, data)}
                            isSaving={isSaving}
                            onTabChange={onTabChange}
                            projectType={projectType}
                        />
                    </Col>
                    <Col xs={24} sm={24} xl={8} >
                        <SportbookSettingsComponent
                            settings={ providerSettings?.sportsbookSettings ?? {}}
                            onSave={data => handleSave(PROJECT_PROVIDER_TYPE.SPORTBOOK, data)}
                            isSaving={isSaving}
                            onTabChange={onTabChange}
                            projectType={projectType}
                        />
                    </Col>
                    {
                        projectType === PROJECT_TYPE.RETAIL_SYSTEM && (
                            <Col xs={24} sm={24} xl={8} >
                                <GoldenRaceSettingsComponent
                                    settings={ providerSettings?.goldenRaceSettings ?? {}}
                                    onSave={data => handleSave(PROJECT_PROVIDER_TYPE.GOLDEN_RACE, data)}
                                    isSaving={isSaving}
                                    onTabChange={onTabChange}
                                />
                            </Col>
                        )
                    }
                    
                </Row>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** ProviderSettingsComponent propTypes
    * PropTypes
*/
ProviderSettingsComponent.propTypes = {
    /** Project Type */
    projectType: PropTypes.oneOfType(Object.values(PROJECT_TYPE)),
    /** Redux action to save project retail Provider settings */
    saveProjectRetailProviderSettings: PropTypes.func,
    /** Redux action to save project terminal Provider settings */
    saveProjectTerminalProviderSettings: PropTypes.func,
    /** Redux state property, is true when provider settings is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when provider settings is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the retail provider settings of current editing project  */
    retailProviderSettings: providerSettingsType,
    /** Redux state, represents the terminal provider settings of current editing project  */
    terminalProviderSettings: providerSettingsType,
    /** Redux action to get project retail provider settings */
    getProjectRetailProviderSettings: PropTypes.func,
    /** Redux action to get project terminal provider settings */
    getProjectTerminalProviderSettings: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveProjectRetailProviderSettings: data => {
            dispatch(saveProjectRetailProviderSettings(data));
        },

        getProjectRetailProviderSettings: () => {
            dispatch(getProjectRetailProviderSettings())
        },

        saveProjectTerminalProviderSettings: data => {
            dispatch(saveProjectTerminalProviderSettings(data));
        },

        getProjectTerminalProviderSettings: () => {
            dispatch(getProjectTerminalProviderSettings())
        }
    }
)

const mapStateToProps = state => {
    return {
        retailProviderSettings: state.projects.edit.retailProviderSettings,
        terminalProviderSettings: state.projects.edit.terminalProviderSettings,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSettingsComponent)