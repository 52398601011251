import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';

import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Terminal Edit Page Component */
const TerminalEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.terminals.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.TERMINAL_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.terminals'), path: Paths.TERMINALS }}
        />
    )
}


export default TerminalEditComponent;
