import moment from "moment-timezone";

import { getUser } from 'utils/auth';

import 'moment/dist/locale/ru';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fa';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/ar';
import 'moment/dist/locale/ko';
import 'moment/dist/locale/zh-cn';
import 'moment/dist/locale/hi';
import 'moment/dist/locale/bn-bd';
import 'moment/dist/locale/he';

import {DATE_FORMAT, DATE_PICKER_RANGES, TIME_FORMAT} from "constants/common.constants";

import timezones from "systemData/timezones";

class DateService {
    constructor(dateFormat = DATE_FORMAT.FORMAT_1, timeFormat=TIME_FORMAT._24_HOUR, timeZone=null) {
        this._handlers = [];

        this.dateFormat = dateFormat && dateFormat !== 0 ? dateFormat : DATE_FORMAT.FORMAT_1;
        this.timeFormat = timeFormat && timeFormat !== 0 ? timeFormat : TIME_FORMAT._24_HOUR;
        this.timeZone = timeZone;

        this.applyTimezone();
    }

    setDateFormat(dateFormat){
        if (dateFormat === 0) return;
        this.dateFormat = dateFormat;
        this.triggerHandlers();
    }

    setTimeFormat(timeFormat){
        if (timeFormat === 0) return;
        this.timeFormat = timeFormat;
        this.triggerHandlers();
    }

    setTimeZone(timeZone){
        this.timeZone = timeZone;
        this.applyTimezone();
        this.triggerHandlers();
    }

    applyTimezone(){
        if(this.timeZone !== null){
            const timezoneOffsetMapping = this.getTimeZonesByOffset(this.timeZone);
            const availableTimezones = timezoneOffsetMapping[this.timeZone];
            if(availableTimezones && availableTimezones[0]){
                moment.tz.setDefault(availableTimezones[0]);
            }
        }
    }

    triggerHandlers() {
		this._handlers.forEach(handler => handler(this.getSettings()));
	}

    subscribe(handler) {
		this._handlers.push(handler);
	}

	unsubscribe(handler) {
		this._handlers = this._handlers.filter(h => h !== handler);
	}

    getSettings(){
        return (
            {
                dateFormat: this.dateFormat,
                timeFormat: this.timeFormat
            }
        )
    }

    getFormat(time = true, date = true){
        let dateFormat = null, timeFormat = null;

        switch (this.dateFormat) {
            case DATE_FORMAT.FORMAT_1:
                dateFormat = "YYYY-MM-DD";
                break;
            case DATE_FORMAT.FORMAT_2:
                dateFormat = "DD-MMM-YYYY";
                break;
            case DATE_FORMAT.FORMAT_3:
                dateFormat = "DD.MM.YYYY";
                break;
            case DATE_FORMAT.FORMAT_4:
                dateFormat = "DD/MM/YYYY";
                break;
            case DATE_FORMAT.FORMAT_5:
                dateFormat = "MMM DD,YYYY";
                break;
            case DATE_FORMAT.FORMAT_6:
                dateFormat = "MM/DD/YYYY";
                break;
            default:
                dateFormat = "YYYY-MM-DD";
                break;
        }

        switch (this.timeFormat) {
            case TIME_FORMAT._24_HOUR:
                timeFormat = "HH:mm";
                break;
            case TIME_FORMAT._12_HOUR:
                timeFormat = "hh:mm A";
                break;
            default:
                timeFormat = "HH:mm";
                break;
        }

        let format = "";

        if (date) {
            format = dateFormat;
        }

        if (time) {
            format += (" " + timeFormat);
        }

        format = format.trim();

        return format;
    }

    getDate(date, format){
        return moment(date, format);
    }

    makeUtcDateToLocal(date, format){
        if(this.timeZone === null){
            return moment.utc(date, format).local();
        }
        return moment.utc(date, format).utcOffset(this.timeZone, false);
    }

    format(dateTime, time = true, date = true, isUtc = true, inputFormat, forceDefault){
        if (!dateTime) return "";

        const defaultFormat = (date ? "YYYY-MM-DD" : "") + (time ? " HH:mm" : "")
        const dateTimeFormat = forceDefault ? defaultFormat : this.getFormat(time, date);

        if(isUtc){
            return this.makeUtcDateToLocal(dateTime, inputFormat).format(dateTimeFormat)
        } else {
            return this.getDate(dateTime, inputFormat).format(dateTimeFormat);
        }
    }

    toString(dateTime, format = "YYYY-MM-DD HH:mm"){
        return this.getDate(dateTime).locale("en").format(format);
    }

    toISOString(dateTime){
        return this.getDate(dateTime).utc().toISOString();
    }

    getTimeZone(){
        if(this.timeZone === null){
            return -(new Date()).getTimezoneOffset() / 60;
        }
        return this.timeZone
    }

    roundHour(date){
        return date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');
    }

    getNow(roundUpHour=false){
        const date = this.getDate();
        if(!roundUpHour) return date;
        return this.roundHour(date);
    }

    yesterday(roundUpHour=false){
        const date = this.getDate().subtract(1, 'days');
        if(!roundUpHour) return date;
        return this.roundHour(date);
    }

    tomorrow(roundUpHour=false){
        const date = this.getDate().add(1, 'days');
        if(!roundUpHour) return date;
        return this.roundHour(date);
    }

    startOfTomorrow(){
        return this.tomorrow().startOf('day');
    }

    startOfLastMonth(){
        return this.getNow(true).subtract(1,'months').startOf('month');
    }

    startOfDay(date){
        return this.getDate(date).startOf('day');
    }

    endOfDay(date) {
        return this.getDate(date).endOf('day');
    }

    startOfNextDay(date) {
        return this.getDate(date).add(1,'day').startOf('day');
    }

    endOfTomorrow(){
        return this.tomorrow().endOf('day');
    }

    endOfLastMonth(){
        return this.getNow(true).subtract(1,'months').endOf('month').add(1, "days");
    }

    daysAgo(count, roundUpHour=false){
        const date = this.getDate().subtract(count, 'days');
        if (!roundUpHour) return date;
        return this.roundHour(date);
    }

    monthsAgo(count, roundUpHour=false){
        const date = this.getDate().subtract(count*30, 'days');
        if (!roundUpHour) return date;
        date.add(1, 'days');
        return this.roundHour(date);
    }

    firstDayOfCurrentMonth(){
        return this.getDate().startOf('month');
    }

    firstDayOfCurrentYear(){
        return this.getDate().startOf('year');
    }

    firstDayOfLastYear(){
        return this.getDate().subtract(1, "year").startOf('year');
    }

    getCurrentMonthDaysCount(){
        return this.getDate().daysInMonth();
    }

    mergeDateAndTime(date, time){
        const d = this.getDate(date);
        d.set('hour', time.hour()).set('minute', time.minutes()).set('second', time.seconds());
        return d;
    }

    getAllFormatedHours(){
        const hours = [];
        let today = this.getNow(true);
        today.set("minute", 0)
        for (let hour = 0; hour < 24; hour++) {
            today.set("hour", hour);
            hours.push(this.format(today, true, false));
        }
        return hours;
    }

    getWeekDays(){
        return ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    }

    getMonths(){
        return ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    }

    getCurrentWeekDays(){
        const days = [];
        for (let i = 1; i < 8; i++) {
            days.push(this.getDate().day(i))
        }
        return days;
    }

    getCurrentMonthDays(){
        const daysInMonth = this.getCurrentMonthDaysCount();
        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(this.getDate().date(i))
        }
        return days;
    }

    getCurrentYMD(){
        const date = this.getNow();
        return {
            year: date.year(),
            month: date.month() + 1,
            day: date.date(),
            weekday: date.day()
        }
    }

    isAfter(dateTime, compare){
        if(!dateTime) return false;
        const d = this.getDate(dateTime);
        return d.isAfter(compare);
    }

    isBefore(dateTime, compare){
        if(!dateTime) return false;
        const d = this.getDate(dateTime);
        return d.isBefore(compare);
    }

    isInRange(dateTime, start, end){
        return !this.isBefore(dateTime, start) && !this.isAfter(dateTime, end);
    }

    getDifference(date1, date2, unit){
        return this.getDate(date1).diff(this.getDate(date2), unit);
    }

    humanizeDuration(duration){
        return moment.duration( duration, "milliseconds" ).humanize()
    }

    setUnit(date, unit, value){
        return this.getDate(date).set(unit, value)
    }

    addUnit(date, value, unit){
        return this.getDate(date).add(value, unit)
    }

    midnight(date) {
        return this.getDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    getRanges(){
        return [
            {
                label: DATE_PICKER_RANGES.TODAY,
                value: [
                    this.midnight(this.getNow(true)),
                    this.midnight(this.tomorrow())
                ]
            },
            {
                label: DATE_PICKER_RANGES.YESTERDAY,
                value: [
                    this.midnight(this.yesterday()),
                    this.midnight(this.getNow(true)),
                ]
            },
            {
                label: DATE_PICKER_RANGES.THIS_MONTH,
                value: [
                    this.midnight(this.firstDayOfCurrentMonth()),
                    this.midnight(this.tomorrow())
                ]
            },
            {
                label: DATE_PICKER_RANGES.LAST_MONTH,
                value: [
                    this.midnight(this.startOfLastMonth()),
                    this.midnight(this.endOfLastMonth())
                ]
            },
            {
                label: DATE_PICKER_RANGES.LAST_3_MONTH,
                value: [
                    this.midnight(this.monthsAgo(3).add(1,'days')),
                    this.midnight(this.tomorrow())
                ]
            },
            {
                label: DATE_PICKER_RANGES.YTD,
                value: [
                    this.midnight(this.firstDayOfCurrentYear()),
                    this.midnight(this.tomorrow())
                ]
            },
            {
                label: DATE_PICKER_RANGES.LAST_YEAR,
                value: [
                    this.midnight(this.firstDayOfLastYear()),
                    this.midnight(this.firstDayOfCurrentYear())
                ]
            }
        ]
    }

    setLocale(locale){
        moment.locale(locale)
    }

    getTimezoneOffset(timezone) {
        const now = new Date();
        const targetDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
        const utcDate = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
        const offset = (targetDate - utcDate) / (1000 * 60);
        return offset;
    }

    getSupportedTimezones(){
        return Intl.supportedValuesOf('timeZone')
    }

    getTimeZonesByOffset(){
        const allList = this.getSupportedTimezones();
        const mapping = {};
        allList.forEach(timezone => {
            const tz = this.getTimezoneOffset(timezone)/60;
            const strTz = String(tz);
            if(!mapping[strTz]){
                mapping[strTz] = [];
            }
            mapping[strTz].push(timezone);
        });
        return mapping;
    }
}

const timeZoneId = getUser()?.timeZoneId;
const timeZoneOffset = timeZoneId ? timezones.find( t => t.id === timeZoneId)?.timeZone ?? null : null;

const instance = new DateService(DATE_FORMAT.FORMAT_1, TIME_FORMAT._24_HOUR, timeZoneOffset);

export default instance;
